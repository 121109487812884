import React from "react";
import "./NoContent.css";
import { compileAsync } from "sass";

const NoContent = ({translator}) => {

    const noContentHandler = () => {
        const coFilterNotNull = sessionStorage.getItem("offerCoFilter")!==null || sessionStorage.getItem("diffusionCoFilter")!==null || sessionStorage.getItem("revenuCoFilter")!==null;
        const stateFilterNotNull = sessionStorage.getItem("offerStateFilter")!==null;
        const spFilterNotNull = sessionStorage.getItem("offerSpFilter")!==null || sessionStorage.getItem("diffusionSpFilter")!==null || sessionStorage.getItem("revenuSpFilter")!==null;
        const filterSet = coFilterNotNull || stateFilterNotNull || spFilterNotNull;
        
        if(filterSet){
            return translator("no_content_with_filter")
        } else {
            return translator("no_content_without_filter")
        }
    }

    return (
        <div className="no-content-container">
            <img style={{width: "150px", height: "150px"}} src={require("../components/icons/searching.png")} />
            <div className="no-content-info">
                <div className="no-content-info-title">
                    {translator("no_content")}
                </div>
                <div className="no-content-info-text-container">
                    <div className="no-content-info-text-subtitle"></div>
                    <div className="no-content-info-text">{noContentHandler()}</div>
                </div>
            </div>
        </div>
    )
}

export default NoContent;