import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
import { Modal, Form as ModalForm, Row, Col } from 'react-bootstrap';
import "../components/UserLogin.css";
import { getCpList, getCpId, addNewCp } from "./UserManip";
import { addFirstLoginUserToHF } from "../blockchainManip/BCManip";
import LoginNavbar from "../components/LoginNavbar";
import LoadingButton from '../components/LoadingButton';
import { hoverSubmitHandler, unhoverSubmitHandler } from "../components/ButtonManip";
import { getCpCode } from "../blockchainManip/CpCode";
import PropTypes from "prop-types";

const SignUp = ({translator}) => {

    const [cognitoAuthSDK, setCognitoAuthSDK] = useState(null);
    const [cps, setCps] = useState([]);
    const [cpName, setCpName] = useState("");
    const [cpUserName, setCpUserName] = useState("");
    const [cpUserPwd, setCpUserPwd] = useState("");
    const [cpUserEmail, setCpUserEmail] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [showCodeBox, setShowCodeBox] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const form = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const cps = await getCpList().then((res) => res.data);
            var cpOptions = [];
            for (let i = 0; i < cps.length; i++) {
                const cp = cps[i].cp_name;
                var cpOption = {
                    value: cp,
                    label: cp
                }
                cpOptions.push(cpOption);
            }
            setCps(cpOptions);

            try {
                const n7sdkInstance = window.N7SDK;
                const n7sdk = new n7sdkInstance();
                setCognitoAuthSDK(n7sdk.CpUserAuth);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const onFinish = async () => {
        setShowLoader(true);
        try {
            cognitoAuthSDK.registerUser(cpUserName, cpUserPwd, cpUserEmail).then(res => {
                setShowLoader(true);
                setShowCodeBox(true);
            }).catch(err => {
                alert(err);
                window.location.reload();
            })
        } catch(error){
            console.error(error);
        }
    }

    const cpNameHandler = (value) => {
        setCpName(value);
    }

    const cpNameFilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

    const cpUserNameHandler = (event) => {
        setCpUserName(event.target.value);
    }
    
    const cpUserPwdHandler = (event) => {
        setCpUserPwd(event.target.value);
    }

    const cpUserEmailHandler = (event) => {
        setCpUserEmail(event.target.value);
    }

    const formVerification = () => {
        const companyNotNull = cpName!=="";
        const userNameNotNull = cpUserName!=="";
        const userPwdNotNull = cpUserPwd!=="";
        const userEmailNotNull = cpUserEmail!=="";
        const userEmailFormatcorrect = validEmailAddress(cpUserEmail);
        if(companyNotNull&&userNameNotNull&&userPwdNotNull&&userEmailNotNull&&userEmailFormatcorrect){
            return(
                <div className="login-submit-btn">
                    <LoadingButton 
                        text={translator('send_verification_code')} 
                        className="n7-button normal-button" 
                        onSubmit={onFinish} 
                        loading={showLoader} 
                        disabled={showLoader} 
                    />
                    <Link className="attention-tag-text" to="/">{translator("sign_in")}</Link>
                </div>
            ) 
        } else {
            return (
                <div className="login-submit-btn">
                    <button className="n7-button normal-button disabled-button" disabled>{translator("send_verification_code")}</button>
                    <Link className="attention-tag-text" to="/">{translator("sign_in")}</Link>
                </div>
            )
        }
    }

    const validEmailAddress = (userMail) => {
        const format = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return format.test(String(userMail).toLowerCase())
    }

    const hideCodeBox = () => {
        setShowCodeBox(false);
    }

    const verificationCodeHandler = (event) => {
        setVerificationCode(event.target.value);
    }

    const confirmation = async () => {
        setShowModalLoader(true);
        cognitoAuthSDK.confirmeUser(cpUserName, verificationCode).then(async () => {
            const cpId = await getCpId(cpName).then(res => res.data[0].cp_id);
            let user = {
                cpCompany: cpId,
                cpUserName: cpUserName,
                cpUserPwd: cpUserPwd
            }
            addNewCp(user).then(() => {
                cognitoAuthSDK.login(cpUserName, cpUserPwd, (error, token) => {
                    let user = {
                        username: cpUserName,
                        cpCode: getCpCode(cpName)
                    }
                    addFirstLoginUserToHF(user, token).then(() => {
                        window.location.assign("/");
                    }).catch(err => {
                        alert(err);
                    });
                })
            }).catch(err => {
                alert(err);
            })
        }).catch(err => {
            alert(err);
            setShowModalLoader(false);
        })
    }

    if(cps !== null){
        return (
            <div className="wrapper">
                <div className="navbar">
                    <LoginNavbar />
                </div>
                <div className="login-container">
                    <div className="login-form-container">
                        <div className="login-input-container">
                            <Form 
                                name="normal_login"
                                layout="vertical"
                                className="login-inputs"
                            >
                                <Form.Item
                                    name="content_provider_name"
                                    label={translator("company_name")}
                                    rules={[{
                                        required: true,
                                        message: translator("enter_company_name_msg")
                                    }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={translator('your_company_name')}
                                        onChange={cpNameHandler}
                                        filterOption={cpNameFilterOption}
                                        options={cps}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="content_provder_user_name"
                                    label={translator("user_name")}
                                    rules={[{
                                        required: true,
                                        message: translator('enter_user_name_msg')
                                    }]}>
                                    <Input  
                                        placeholder={ translator('your_user_name') } 
                                        autoComplete="off"
                                        value={cpUserName}
                                        onChange={cpUserNameHandler}/>
                                </Form.Item>
                                <Form.Item
                                    name="content_provider_user_pwd"
                                    label={translator("pwd")}
                                    rules={[{
                                        required: true,
                                        message: translator('enter_user_pwd_msg')
                                    }]}>
                                    <Input.Password
                                        type="password"
                                        placeholder={translator('your_pwd')}
                                        autoComplete="off"
                                        value={cpUserPwd}
                                        onChange={cpUserPwdHandler}/>
                                </Form.Item>  
                                <Form.Item
                                    name="content_provider_user_email"
                                    label={translator("email")}
                                    rules={[{
                                        required: true,
                                        message: translator('enter_user_email')
                                    }]}>
                                    <Input
                                        style={{ width: "300px" }}
                                        placeholder={translator('your_email')}
                                        autoComplete="off"
                                        value={cpUserEmail}
                                        onChange={cpUserEmailHandler}/>
                                </Form.Item>  
                            </Form>
                        </div>
                        {formVerification()}
                    </div>
                </div>
                <div className="footer">
                    <div className="footer-left-panel">
                        <div className="footer-left-panel-about-n7">
                            <a id="about-n7-link" href="#">
                                {translator('about_n7_project')}
                            </a>
                        </div>
                        <div className="footer-left-panel-contact">
                            <a id="contact-link" href="#">
                                {translator('contact_n7')}
                            </a>
                        </div>
                    </div>
                    <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
                </div>
                <Modal show={showCodeBox} onHide={hideCodeBox} style={{ position: "fixed", zIndex: "9999" }}>
                    <Modal.Header>
                        <Modal.Title>
                            {translator('verification_code')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="user-confirmation">
                        <ModalForm ref={form}>
                            <ModalForm.Group as={Row} className="mb-3">
                                <Col>
                                    <ModalForm.Control type="text" required onChange={event=>{verificationCodeHandler(event)}} />
                                </Col>
                            </ModalForm.Group>
                        </ModalForm>
                    </Modal.Body>
                    <Modal.Footer>
                        { verificationCode=="" ? 
                            <button className="n7-button normal-button disable-button" disabled >{translator("validate")}</button> : 
                            <LoadingButton 
                                text={translator("validate")} 
                                onSubmit={confirmation} 
                                loading={showModalLoader} 
                                disabled={showModalLoader} 
                            /> 
                        }
                        </Modal.Footer>
                </Modal>
            </div>
        )
    }   
}

SignUp.propTypes = {
    setUser: PropTypes.func.isRequired,
};

export default SignUp;
