import React, { useState, useEffect } from 'react';
import { Form, Slider, DatePicker } from 'antd';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import {hoverHandler, unhoverHandler, hoverSubmitHandler, unhoverSubmitHandler, hoverCancelHandler, unhoverCancelHandler} from '../components/ButtonManip';
import {getCoOffer, getSpList, getSpInNego, sendOffer, setContentState, updateCpCoUploadStatus} from './OfferManip';
import {addValidCPCONegotiationToBC} from '../blockchainManip/BCManip';
import {calculateContainerHeight} from '../components/ContainerDimensionManip';
import LoadingButton from '../components/LoadingButton';
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import { getCpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import cpUserDecoRoutes from '../components/CpUserDecoRoutes';

const SendOffer = ({translator, filters, cpHash}) => {
    const [deco, setDeco] = useState(null);
    const [spList, setSpList] = useState(null);
    const [successionNegotiation, setSuccessionNegotiation] = useState(null);
    const [successionNegotiationId, setSuccessionNegotiationId] = useState(null);
    const [sps, setSps] = useState([]);
    const [form] = Form.useForm();
    const [servicePrice, setServicePrice] = useState(0);
    const [serviceStartDate, setServiceStartDate] = useState(null);
    const [serviceEndDate, setServiceEndDate] = useState(null);
    const dateFormat = ({
        fr: "DD/MM/YYYY",
        en: "MM/DD/YYYY"
    });
    const [showLoader, setShowLoader] = useState(false);
    const [contentHeight, setContentHeight] = useState("");
    const [cpNotExistError, setCpNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const cpData = await getCpByHash(cpHash).then(res => res.data);
            if(cpData!==undefined && cpData.length!==0){
                setDeco(getDeco(cpUserDecoRoutes, cpData[0].cp_name));
            } else {
                setCpNotExistError(true);
            }

            const url = window.location.href;
            const urlArray = url.split("/");
            const negotiationId = urlArray[urlArray.length - 2];
            const coOffer = await getCoOffer(negotiationId).then(res => res.data);      
            const spOptions = [{value: 6, label: "Sport All"}];

            setSpList(spOptions);
            setSps([{spId: 6, spName: "Sport All"}]);
            setSuccessionNegotiation(coOffer.length===0 ? coOffer : coOffer[0]);
            setSuccessionNegotiationId(negotiationId);

            const contentHeightValue = calculateContainerHeight();
            if (contentHeightValue !== contentHeight) {
                setContentHeight(contentHeightValue);
            }
        };

        init();
    }, []);

    useEffect(() => {
        const initialValue = form.getFieldValue("servicePrice");
        if(servicePrice!==0 && initialValue!==undefined){
            if(servicePrice!==initialValue){
                form.setFieldsValue({ servicePrice: servicePrice });
            }
        }
    }, [form, servicePrice]);

    const selectedSp = (sp) => {
        let serviceProvider = {
            spId: sp.value,
            spName: sp.label
        }
        return ( 
            <button  
                key={sp.value} 
                className="selected-tag selected-tag-text" 
                style={{border: "none", color: deco.mainColor, boxShadow: deco.shadowColor}}
            >
                {sp.label}
            </button>
        )
    }

    const serviceStartDateHandler = (startDate) => {
        setServiceStartDate(startDate);
    }

    const validStartTime = () => {
        const availabilityStartDateValue = new Date(successionNegotiation.availability_start_date);
        availabilityStartDateValue.setHours(0,0,0,0);
        const availabilityEndDateValue = new Date(successionNegotiation.availability_end_date);
        availabilityEndDateValue.setHours(0,0,0,0);
        if(serviceStartDate!==null){
            const serviceStartDateValue = new Date(serviceStartDate);
            serviceStartDateValue.setHours(0,0,0,0);
            if(serviceStartDateValue.getTime() < availabilityStartDateValue.getTime()){
                return {
                    validateStatus: "error",
                    msg: translator("enter_correct_service_start_date_msg")
                }
            } else if(serviceStartDateValue.getTime() >= availabilityEndDateValue.getTime()) {
                return {
                    validateStatus: "error",
                    msg: translator("enter_correct_service_start_date_msg")
                }
            } else {
                return {
                    validateStatus: "success",
                    msg: ""
                 }
            }
        } else {
            return{
                validateStatus: "success",
                msg: ""
            }
        }
    }

    const serviceEndDateHandler = (endDate) => {
        setServiceEndDate(endDate);
    }

    const validEndTime = () => {
        const serviceStartDateValue = serviceStartDate===null ? new Date(successionNegotiation.availability_start_date) : new Date(serviceStartDate);
        serviceStartDateValue.setHours(0,0,0,0);
        const availabilityEndDateValue = new Date(successionNegotiation.availability_end_date);
        availabilityEndDateValue.setHours(0,0,0,0);
        if(serviceEndDate!==null){
            const serviceEndDateValue = new Date(serviceEndDate);
            serviceEndDateValue.setHours(0,0,0,0);
            if(serviceEndDateValue.getTime() <= serviceEndDateValue.getTime()){
                return {
                    validateStatus: "error",
                    msg: translator("enter_correct_service_end_date_msg")
                }
            } else if(serviceEndDateValue.getTime() > availabilityEndDateValue.getTime() ){
                return {
                    validateStatus: "error",
                    msg: translator("enter_correct_service_end_date_msg")
                }
            } else {
                return {
                    validateStatus: "success",
                    msg: ""
                 }
            }
        } else {
            return {
               validateStatus: "success",
               msg: ""
            }
        }
    }

    const serviceDuration = () => {
        const end = serviceEndDate===null ? new Date(successionNegotiation.availability_end_date) : new Date(serviceEndDate);
        end.setHours(0,0,0,0);
        const start = serviceStartDate ===null ? new Date(successionNegotiation.availability_start_date) : new Date(serviceStartDate);
        start.setHours(0,0,0,0);
        const diffDate = new Date(end-start)
        diffDate.setHours(0,0,0,0);
        const diffDateDay = diffDate.getDate();
        const diffDateMonth = diffDate.getMonth() + 1;
        const diffDateYear = diffDate.getFullYear();
        const diffArray = [diffDateDay, diffDateMonth, diffDateYear];
        const diffDay = Number(Math.abs(diffArray[0]) - 1);
        const diffMonth = Number(Math.abs(diffArray[1]) -1);
        const diffYear = Number(Math.abs(diffArray[2]) - 1970);
        const duration = diffYear + " " + translator("year_s") + " " + diffMonth + " " + translator("month_s") + " " + diffDay + " " + translator("day_s");
        return duration;
    }

    const setUTCDate = (date) => {
        const dateFormatDate = new Date(date);
        return Date.UTC(dateFormatDate.getUTCFullYear(), 
                        dateFormatDate.getUTCMonth(), 
                        dateFormatDate.getUTCDate(), 
                        dateFormatDate.getUTCHours(),
                        dateFormatDate.getUTCMinutes(),
                        dateFormatDate.getUTCSeconds());
    }

    const sendOfferHandler = () => {
        setShowLoader(true);
        const receivedTime = new Date();
        const receivedUTCTime = new Date(setUTCDate(receivedTime)).toISOString();
        const volumeCommitment = 0;
        const serviceStartDateValue = serviceStartDate===null ? successionNegotiation.availability_start_date : new Date(this.setUTCDate(serviceStartDate)).toISOString();
        const serviceEndDateValue = serviceEndDate===null ? successionNegotiation.availability_end_date : new Date(this.setUTCDate(serviceEndDate)).toISOString();
        const deliveryDuration = serviceDuration();
        const contentId = successionNegotiation.content_unique_id;
        const contentOwner = successionNegotiation.content_owner;
        const contentProvider = successionNegotiation.content_provider;
                        
        let cpSpNegotiation = {
            receivedTime: receivedUTCTime,
            servicePrice: 0,
            volumeCommitment: volumeCommitment,
            serviceStartDate: serviceStartDateValue,
            serviceEndDate: serviceEndDateValue,
            deliveryDuration: deliveryDuration,
            fromCpOrSp: "cp",
            contentID: contentId,
            contentOwner: contentOwner,
            contentProvider: contentProvider,
            serviceProvider: sps[0].spId,
            successionNegotiation: successionNegotiationId,
            notificationEmail: JSON.parse(sessionStorage.getItem("user")).cpUserMail,
        }   
        sendOffer(cpSpNegotiation).then(res => {
            setShowLoader(false);
            alert(translator("proposal_sent"));
            window.location.assign("/" + cpHash + "/offers")
        }).catch(err => {
            console.error(err);
            setShowLoader(false);
            alert(translator("send_proposal_failed"))
        })
    }

    const validity = () => {
        const dateValidity = validStartTime().validateStatus==="success" && validEndTime().validateStatus==="success";
        const spSelected = sps.length!==0;
        if(dateValidity && spSelected){
            return <LoadingButton 
                        text={translator("validate_proposal")} 
                        className="n7-button normal-button" 
                        color={deco.secondaryColor}
                        onSubmit={()=>sendOfferHandler()} 
                        loading={showLoader} 
                        disabled={showLoader} 
                    /> 
        } else {
            return <button className="n7-button disabled-button" disabled style={{color: deco.secondaryColor}}>{translator("validate_proposal")}</button>                          
        }
    }

    if(successionNegotiation !== null){
        if(successionNegotiation.length===0 || cpNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else {
            const sliderBorderColor = "0 0 0 2px " + deco.mainColor;
            const sliderShadowColor = "0 0 0 4px " + deco.mainColor;
            const markMax = successionNegotiation.cp_reward;

            const sliderMark = {
                0: {
                    style: {
                        color: deco.secondaryColor,
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 500
                    }, 
                    label: 0 + "€"
                }
            }

            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + cpHash + "/offers"}
                                contentName={successionNegotiation.content_name}
                            />
                            <Form
                                name="send-proposal"
                                className="form-container" style={{maxHeight : contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_provider")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                {spList.map((sp) => (
                                                    selectedSp(sp)
                                                ))}
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_price")}
                                            </div>
                                            <div style={{width: "100%", display: "inline-flex", flexDirection: "row", gap: "8px"}}>
                                                <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>0</div>
                                                <Form.Item noStyle name="service_price" initialValue={0.00}>
                                                    <Slider
                                                        style={{width:"100%", "--slider-border-color": sliderBorderColor, "--slider-shadow-color": sliderShadowColor, paddingTop: 0}}
                                                        min={0.00} max={markMax} marks={sliderMark} step={0.01} /* onChange={servicePriceHandler} */
                                                        disabled
                                                    />
                                                </Form.Item>
                                                <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>{markMax + "€"}</div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_start_date")}
                                            </div>
                                            <Form.Item
                                                name="startTime"
                                                validateStatus={validStartTime().validateStatus}
                                                help={validStartTime().msg}
                                                style={{width: "100%"}}
                                            >
                                                <DatePicker
                                                    placeholder={new Date(successionNegotiation.availability_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                    onChange={serviceStartDateHandler}
                                                    format={dateFormat[localStorage.getItem("i18nextLng")]}
                                                    style={{"--date-default-color": deco.mainColor}}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_end_date")}
                                            </div>
                                            <Form.Item
                                                name="endTime"
                                                validateStatus={validEndTime().validateStatus}
                                                help={validEndTime().msg}
                                                style={{width: "100%"}}
                                            >
                                                <DatePicker
                                                    placeholder={new Date(successionNegotiation.availability_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                    onChange={serviceEndDateHandler}
                                                    format={dateFormat[localStorage.getItem("i18nextLng")]}
                                                    style={{"--date-default-color": deco.mainColor}}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-info-panel">
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_id")}
                                            </div>
                                            <div className="form-info-text" style={{ color: deco.secondaryColor}}>
                                                {successionNegotiation.content_unique_id}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_owner")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {successionNegotiation.content_owner_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_start_date")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(successionNegotiation.availability_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_start_date")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(successionNegotiation.availability_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-button-panel">
                                    {validity(markMax)}
                                </div>           
                            </Form>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    }
}

export default SendOffer;