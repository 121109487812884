import axios from "axios";

const BASIC_URL="https://n7-demo-content-provider.nseven.xyz/api/v1/cp";

const getCoList = () => {
    const url=BASIC_URL + "/cpCoNegotiations/coList";
    return axios.get(url);
}

const getAllCoOffers = (cpHash) => {
    const url=BASIC_URL + "/cpCoNegotiations/" + cpHash + "/all";
    return axios.get(url);
}

const getCoOffer = (negotiationId) => {
    const url=BASIC_URL + "/cpCoNegotiations/id/" + negotiationId;
    return axios.get(url);
}

const acceptOffer = (offer) => {
    const url=BASIC_URL + "/cpCoNegotiations/accepted";
    return axios.put(url, offer);
}

const setContentState = (contentState) => {
    const url=BASIC_URL + "/cpCoNegotiations/contentStateSp";
    return axios.post(url, contentState);
}

const rejectOffer = (offer) => {
    const url=BASIC_URL + "/cpCoNegotiations/rejected";
    return axios.put(url, offer);
}

const negotiate = (offer) => {
    const url=BASIC_URL + "/cpCoNegotiations/negotiation";
    return axios.post(url, offer);
}

const cancelNegotiation = (negotiationId) => {
    const url=BASIC_URL + "/cpCoNegotiations/" + negotiationId + "/cancelled";
    return axios.delete(url);
}

const updateCpCoUploadStatus = (offerId) => {
    const url=BASIC_URL + "/cpCoNegotiations/" + offerId + "/uploaded";
    return axios.put(url);
}

const getAllSpOffers = (cpHash) => {
    const url=BASIC_URL + "/cpSpNegotiations/" + cpHash + "/all";
    return axios.get(url);
}

const getSpList = () => {
    const url=BASIC_URL + "/cpSpNegotiations/spList";
    return axios.get(url);
}

const getSpInNego = (contentInfo) => {
    const contentId = contentInfo.contentId;
    const co = contentInfo.co;
    const cp = contentInfo.cp;
    const url=BASIC_URL + "/cpSpNegotiations/spInNego/" + contentId + "/" + co + "/" + cp;
    return axios.get(url);
}

const sendOffer = (offer) => {
    const url=BASIC_URL + "/cpSpNegotiations";
    return axios.post(url, offer);
}

const cancelOffer = (offerId) => {
    const url=BASIC_URL + "/cpSpNegotiations/" + offerId + "/deleted";
    return axios.delete(url);
}

const getContentStateId = (offerId) => {
    const url=BASIC_URL + "/cpSpNegotiations/" + offerId + "/contentStateId";
    return axios.get(url);
}

const acceptNegotiation = (offer) => {
    const url=BASIC_URL + "/cpSpNegotiations/accepted";
    return axios.put(url, offer);
}

const updateContentState = (contentState) => {
    const url=BASIC_URL + "/cpSpNegotiations/contentState";
    return axios.put(url, contentState);
}

const updateUploadStatus = (offerId) => {
    const url=BASIC_URL + "/cpSpNegotiations/" + offerId + "/uploaded";
    return axios.put(url);
}

const getSpOffer = (negotiationId) => {
    const url=BASIC_URL + "/cpSpNegotiations/id/" + negotiationId;
    return axios.get(url);
}

export {getCoList, getAllCoOffers, getCoOffer, acceptOffer, setContentState, rejectOffer, negotiate, cancelNegotiation, updateCpCoUploadStatus, getAllSpOffers, getSpList, getSpInNego, sendOffer, cancelOffer, getContentStateId, acceptNegotiation, updateContentState, updateUploadStatus, getSpOffer};
