import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import getDeco from '../components/DecoManip';
import cpUserDecoRoutes from "../components/CpUserDecoRoutes" 
import {getCoList, getCoOffer, getAllCoOffers, getAllSpOffers, getSpList, cancelOffer} from './OfferManip';
import {hoverSubmitHandler, unhoverSubmitHandler, hoverDeleteHandler, unhoverDeleteHandler} from '../components/ButtonManip';
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import NoContent from '../components/NoContent';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import LoadingPage from '../components/LoadingPage';
import { getCpByHash } from '../userLogin/UserManip';
import Error1 from '../errorManip/Error1';

const Offers = ({translator, filters, cpHash}) => {
    const [deco, setDeco] = useState(null);
    const [offers, setOffers] = useState(null);
    const [cos, setCos] = useState([]);
    const [sps, setSps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableStateWidth, setTableStateWidth] = useState("");
    const [tableCoWidth, setTableCoWidth] = useState("");
    const [tableSpWidth, setTableSpWidth] = useState("");
    const [cpNotExistError, setCpNotExistError] = useState(false);

    const tagContainerStyleHandler = (coOrSpOffer, valid) => {
        switch (valid) {
            case "":
                return coOrSpOffer==="co" ? "valid-tag table-state" : "info-tag table-state";

            case "accepted" : 
                return "valid-tag table-state"

            case "sp_accepted":
                return "valid-tag table-state"
        
            default:
                return "info-tag table-state";
        }
    }

    const tagTextStyleHandler = (coOrSpOffer, valid) => {
        switch (valid) {
            case "":
                return coOrSpOffer==="co" ? "valid-tag-text" : "info-tag-text";

            case "accepted" : 
                return "valid-tag-text"

            case "sp_accepted":
                return "valid-tag-text"
        
            default:
                return "info-tag-text";
        }
    }

    const checkOfferHandler = (negotiationId, coOrSpOffer) => {
        switch (coOrSpOffer) {
            case "co":
                return (
                    <Link to={"/" + cpHash + "/offers/co/" + negotiationId + "/checkOffer"}>
                        <input type='button' className="table-action-buttons n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} value={translator("get_cp_co_negotiation")} style={{color: deco.secondaryColor}}/>
                    </Link>
                )
        
            case "sp" : 
                return (
                    <Link to={"/" + cpHash + "/offers/sp/" + negotiationId + "/checkOffer"}>
                        <input type='button' className="table-action-buttons n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} value={translator("get_cp_sp_negotiation")} style={{color: deco.secondaryColor}}/>
                    </Link>
                )
        
            default:
                break;
        }
    }

    const completeOfferArray = (offerArray, coOrSpOffer) => {
        for (let i = 0; i < offerArray.length; i++) {
            var offerData = offerArray[i];
            const infoArray = [
                {
                    label: "identifiant",
                    value: offerData.content_unique_id
                }
            ]

            offerData = {
                ...offerData,
                coOrSpOffer: coOrSpOffer,
                info: infoArray
            }
            offerArray[i] = offerData;
        }
        return offerArray;
    }

    const removeSameOffer = (coOfferArray, spOfferArray, allSps) => {
        var negotiationContinueOn = [];

        for (let i = 0; i < spOfferArray.length; i++) {
            const offer = spOfferArray[i];
            negotiationContinueOn.push(offer.succession_negotiation);
        }

        let newCoOfferArray = coOfferArray;

        for (let i = 0; i < negotiationContinueOn.length; i++) {
            const negotiationId = negotiationContinueOn[i];
            newCoOfferArray = newCoOfferArray.filter((offer) => offer.negotiation_id!==negotiationId);
        }

        return newCoOfferArray;
    }

    const setCoFilter = (offers, coList) => {
        var selectedCo = sessionStorage.getItem("offerCoFilter");
        if(selectedCo!==null && selectedCo!=="all"){
            offers = offers.filter((offer) => coList.find(co => co.co_id===offer.content_owner).co_name===selectedCo);
        }
        return offers;
    }

    const findMatchSp = (spList, spId, requestSp) => {
        const foundSp = spList.find(sp => sp.sp_id===spId);
        if(foundSp===undefined){
            return true;
        } else {
            return foundSp.sp_name===requestSp;
        }
    }

    const setSpFilter = (offers, spList) => {
        offers = offers.filter(offer => findMatchSp(spList, offer.service_provider, "Sport All"));
        return offers;
    }

    const getCorrespondStateFilterId = (state, coOrSpOffer) => {
        var stateNotNull = state===null ? (coOrSpOffer==="co" ? "co_pending" : "sp_pending") : state;
        switch (stateNotNull) {
            case "co_pending":
                return "co_offer_received";

            case "sp_pending":
                return "waiting_for_sp_reply"
        
            default:
                return state;
        }
    }

    const completeOfferArrayForDateComparison = async (offers) => {
        for (let i = 0; i < offers.length; i++) {
            var offer = offers[i];
            var compareDate = offer.received_time_cp_co;
            if(offer.coOrSpOffer==="sp"){
                const successionNegotiation = await getCoOffer(offer.succession_negotiation).then(res => res.data[0]);
                compareDate = successionNegotiation.received_time_cp_co;
            }

            offer = {
                ...offer,
                compareDate: compareDate
            }
            offers[i] = offer;
        }
        return offers
    }

    const sendOfferHandler = (offer) => {
        if(offer.coOrSpOffer==="co" && offer.negotiation_valid==="accepted"){
            return (
                <Link to={"/" + cpHash + "/offers/" + offer.negotiation_id + "/sendOffer"}>
                    <input type='button' className="table-action-buttons n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} value={translator("send_proposal")} style={{color: deco.secondaryColor}}/>
                </Link>
            )
        } else {
            return (
                <></>
            )
        }
    }

    useEffect(() => {
        const init = async () => {
            const cpData = await getCpByHash(cpHash).then(res => res.data);
            if(cpData!==undefined && cpData.length!==0){
                setDeco(getDeco(cpUserDecoRoutes, cpData[0].cp_name));
            } else {
                setCpNotExistError(true);
            }

            var coOffers = await getAllCoOffers(cpHash).then(res => res.data);
            var spOffers = await getAllSpOffers(cpHash).then(res => res.data);

            const coList = await getCoList().then(res => res.data);
            setCos(coList);

            const spList = await getSpList().then(res => res.data);
            setSps(spList);

            coOffers = removeSameOffer(coOffers, spOffers, spList);
            coOffers = completeOfferArray(coOffers, "co");
            spOffers = completeOfferArray(spOffers, "sp");

            var offers = [...coOffers, ...spOffers];
            offers = setCoFilter(offers, coList);
            offers = setSpFilter(offers, spList);

            var selectedState = sessionStorage.getItem("offerStateFilter");
            if(selectedState!==null && selectedState!=="all"){
                offers = offers.filter((offer)=>getCorrespondStateFilterId(offer.negotiation_valid, offer.coOrSpOffer)===selectedState);
            }
            
            offers = await completeOfferArrayForDateComparison(offers);

            offers.sort((offerA, offerB) => {
                var dateA = offerA.compareDate;
                var dateB = offerB.compareDate;
                return new Date(dateB) - new Date(dateA)
            })
            setOffers(offers);

            const contentHeightValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name")===0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableStateWidthValue = calculateTableWidth("table-state")===0 ? "auto" : calculateTableWidth("table-state") + "px";
            const tableCoWidthValue = calculateTableWidth("table-co")===0 ? "auto" : calculateTableWidth("table-co") + "px";
            const tableSpWidthValue = calculateTableWidth("table-sp")===0 ? "auto" : calculateTableWidth("table-sp") + "px";
            if (contentHeightValue !== contentHeight || tableContentNameWidthValue !== tableContentNameWidth || tableCoWidthValue !== tableCoWidth || tableStateWidthValue !== tableStateWidth || tableSpWidthValue !== tableSpWidth) {
                setContentHeight(contentHeightValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableCoWidth(tableCoWidthValue);
                setTableStateWidth(tableStateWidthValue);
                setTableSpWidth(tableSpWidthValue);
            }
        };
        init();
    }, []);

    if(offers !== null){
        if(cpNotExistError){
            return <Error1 translator={translator} />
        } else {
            if(offers.length !== 0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco}
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cos={cos}
                                    sps={sps} 
                                />
                                <div className="table-container" style={{height: contentHeight}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}><p style={{margin:0}}>{translator("content_name")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableStateWidth}}><p style={{margin:0, width: "max-content"}}>{translator("negotiation_state")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableCoWidth}}><p style={{margin:0}}>{translator("content_owner")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableSpWidth}}><p style={{margin:0}}>{translator("service_provider")}</p></th>
                                                <th style={{color: deco.secondaryColor}}>{translator("action")}</th>      
                                                <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {offers.map((offer, id) => 
                                                <tr key = {id} >
                                                    <td style={{color: deco.secondaryColor}}><p className="table-content-name" style={{margin:0, width: "max-content"}}>{offer.content_name}</p></td>
                                                    <td>
                                                        <div className={tagContainerStyleHandler(offer.coOrSpOffer, offer.negotiation_valid===null ? "" : offer.negotiation_valid)}>
                                                            <div className={tagTextStyleHandler(offer.coOrSpOffer, offer.negotiation_valid===null ? "" : offer.negotiation_valid)}>
                                                                {offer.negotiation_valid===null ? (offer.coOrSpOffer==="co" ? translator("co_offer_received") : translator("waiting_for_sp_reply")) : translator(offer.negotiation_valid)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-co" style={{margin:0, width: "max-content"}}>{cos.find(co => co.co_id===offer.content_owner).co_name}</p></td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-sp" style={{margin:0, width: "max-content"}}>{offer.service_provider===undefined ? "N/A" : sps.find(sp => sp.sp_id===offer.service_provider).sp_name}</p></td>
                                                    <td style={{whiteSpace: "nowrap"}}>
                                                        {checkOfferHandler(offer.negotiation_id, offer.coOrSpOffer)}
                                                        {sendOfferHandler(offer)}
                                                    </td>
                                                    <td className="table-infomation-panel" style={{ paddingRight: "15px", textAlign: "right"}}>
                                                        <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={offer.info} /> </span>
                                                        <img alt="info-pop-up-icon" className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>  
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            } else if(offers.length===0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cos={cos}
                                    sps={sps} 
                                />
                                <NoContent translator={translator} />
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            }
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default Offers;