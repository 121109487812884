import React, { useState, useEffect } from "react";

const SpFilter = ({translator, deco, activeFilterGeneral, activeFilterHandler, activeFilter, deactiveFilter, hoverHandler, unhoverHandler, sps, selectSp, getSpFilterPage}) => {

    const [spList, setSpList] = useState([]);

    useEffect(() => {
        setSpList(["Sport All"]);
        // const spNames = sps.map(sp => sp.sp_name);
        // setSpList(["all", ...spNames]);
    }, [sps])

    return (
        <div className="sidebar-filter" id="sp-filter">
            <div className="sidebar-filter-title" style={{color: deco.secondaryColor}}>
                {translator("service_provider_full")}
            </div>
            <div className="sidebar-filter-container">
                {
                    spList.map(
                        sp => (
                            activeFilter("sp", "Sport All")
                            // activeFilterHandler("sp", sp) ? activeFilter("sp", sp) : deactiveFilter("sp", sp)
                        )
                    )
                }
            </div>
            
        </div>
    )
}

export default SpFilter;