import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
.use(initReactI18next)
.use(LanguageDetector)
.init(
    {
        debug: true,
        fallbackLng: 'fr',
        resources: {
            fr: {
                translation: {
                    //login
                    admin_login: "Accès Admin",
                    company_name: "Société",
                    your_company_name: "Nom de votre société",
                    user_name: "Identifiant",
                    your_user_name: "Votre identifiant",
                    pwd: "Mot de passe",
                    your_pwd: "Votre mot de passe",
                    email: "Email",
                    your_email: "Votre email",
                    sign_in: "S'identifier",
                    sign_up: "Créer un compte",
                    send_verification_code: "Envoyer le code de vérification",
                    enter_company_name_msg: "Veilliez saisir le nom de votre société",
                    enter_user_name_msg: "Veilliez saisir votre identifiant!",
                    enter_user_pwd_msg: "Veilliez saisir votre mot de passe!",
                    enter_user_email: "Veilliez saisir votre email!",
                    wrong_company_name_or_wrong_user_name: "Mauvais Nom de Société ou Mauvais Identifiant",
                    wrong_user_name_or_wrong_pwd: "Mauvais identifiant ou mauvais mot de passe",
                    wrong_pwd: "Mauvais Mot de Passe",
                    verification_code: "Code de vérification",
                    //foot
                    about_n7_project: "À PROPOS DU PROJET N7",
                    contact_n7: "CONTACTER N7",
                    made_by_n7_development_team: "réalisé par l'équipe de développement de N7",
                    //loading 
                    waiting_for_bc_response: "Nous attendons une réponse",
                    bc_msg: "La blockchain permet de sécuriser et fiabiliser la transmission des données et requiert un cours instant pour le réaliser.",
                    //errors
                    no_content: "Pas de données à afficher",
                    no_content_with_filter: "Il n’existe pas de données qui correspondent au filtre sélectionné",
                    no_content_without_filter: "Il n'y a pas de données à afficher.",
                    error_404: "Erreur 404",
                    page_not_found: "Page non Trouvée",
                    retry: "Nous vous invitons à ressayer votre action, en cas de nouvelle erreur, nous vous invitons à ressayer plus tard.",
                    bc_error: "Une erreur est survenue sur la blockchain.",
                    upload_failed: "Téléversement échoué",
                    //navbar
                    offers: "Offres",
                    diffusions: "Diffusions",
                    state: "État",
                    //content table
                    information: "Infos",
                    //users
                    content_owner: "CO",
                    content_provider: "CP",
                    service_provider : "SP",
                    content_owner_full: "Content Owner",
                    content_provider_full: "Content Provider",
                    service_provider_full : "Service Provider",
                    no_viewer: "Aucun Viewer",
                    viewer: "Viewer",
                    viewers: "Viewers",
                    viewer_s: "Viewer(s)",
                    //content
                    contents_on_platform: "Contenus sur la Plateforme",
                    no_content_on_platform: "Aucun Contenu sur la Plateforme",
                    content_s_on_platform: "Contenu(s) sur la Plateforme",
                    content_previews: "Aperçus",
                    no_preview: "Aucun Aperçu",
                    content_preview_s: "Aperçu(s)",
                    available_contents: "Contenus Disponibles",
                    no_available_content: "Aucun Contenu Disponible",
                    available_content_s: "Contenu(s) Disponible(s)",
                    no_supplied_content: "Aucun Contenu Disponible",
                    supplied_contents: "Contenus en Distribution",
                    supplied_content_s: "Contenu(s) en Distribution",
                    no_benefits: "Aucune Prestation",
                    //content info
                    content_id: "Identifiant du Contenu",
                    content_name: "Nom du Contenu",
                    upload_date: "Date d'Ajout",
                    content_state: "État du Contenu",
                    content_type: "Type du Contenu",
                    ledger_content_info: "Information du Contenu sur Le Registre Distribué",
                    timestamp: "Horodatage",
                    purchased: "Consommé",
                    not_purchased: "Non Consommé",
                    viewership: "Consommation",
                    proof: "Preuve",
                    no_proof: "Aucune Preuve",
                    proofs: "Preuves",
                    proof_s: "Preuve(s)",
                    //content state
                    all: "Tous",                   
                    waiting_for_co_proposal: "En Attente de Création de l'offre",
                    under_cp_co_negotiation: "En Cours de Négociation CP",
                    available: "Disponible",
                    under_cp_sp_negotiation: "En Cours de Négociation SP",
                    supplied: "En Cours de distribution",
                    contentadded: "Contenu est enregistré sur la blockchain",
                    cpallowed: "CP a le droit de distribuer.",
                    diffusing: "En Cours de distribution",
                    verifying: "En Cours d'Évaluation",
                    proof_not_verified : "Preuve Non Vérifiée",
                    proof_verified: "Preuve Vérifiée",
                    received: "Reçue",
                    receive_time_co_cp: "Date de réception d'offre CO",
                    valid_time_co_cp: "Date de validation d'offre CO",
                    send_time_cp_sp: "Date d'envoie d'offre SP",
                    valid_time_cp_sp: "Date de validation d'offre SP",
                    //negotiation
                    no_negotiation: "Aucune Négociation",
                    negotiation: "Négociation",
                    negotiations: "Négociations",
                    negotiation_s: "Négociation(s)",
                    negotiation_list: "Liste de Négociations",
                    //proposal
                        //proposal state
                    negotiation_state: "État des Offres",
                    proposal_accepted: "Offre est Acceptée",
                    proposal_rejected: "Offre est Rejetée",
                    negotiating_proposal: "Offre est sous Négotiation",
                                //proposal info
                    offer : "Offre",
                    cp_reward: "Marge Aval",
                    content_price: "Prix de Vente au Client",
                    volume_commitment: "Engagement de Volume",
                    availability_start_date: "Date du Début d'Accès",
                    availability_end_date: "Date de la Fin d'Accès",
                    access_duration: "Durée d'Accès",
                    service_price: "Prix de Service",
                    service_start_date: "Date du Début de Service",
                    service_end_date: "Date de la Fin de Service",
                    delivery_duration: "Durée de Distribution",
                    broadcasting_dates: "Dates Diffusion",
                    year_s: "année(s)",
                    month_s: "mois",
                    day_s: "jour(s)",
                    message: 'Message', 
                    rejection_msg: "Motif de Refus",
                    negotiation_msg: "Commentaire de la Négociation",
                            //reply
                    response: "Réponse",
                    response_from_the_content_owner: "Réponse du CO",
                    response_from_the_service_provider: "Réponse du SP",
                                //reply state
                    no_reply_for_this_proposal: "Aucun Réponse pour ce Contenu",
                    co_offer_received: "Offre CO Reçue",
                    waiting_for_sp_reply: "Offre SP en Attente",
                    accepted: "Offre CO Acceptée",
                    rejected: "Offre CO Rejetée",
                    negotiating: "Offre CO Negociée",
                    sp_rejected: "Offer SP Refusée",
                    sp_negotiating: "Offre SP Négociée",
                    sp_accepted: "Offre SP Acceptée",
                    //viewer
                    viewer_email: "E-Mail du Viewer",
                    notification: "Notification",
                    new_available_content_on_our_website: "Un nouveau contenu est disponible pour vous sur notre site!",
                    hello: "Chère testeuse, testeur N7",
                    new_content: "Un nouveau contenu",
                    is_now_available: "est disponible maintenant",
                    is_now_available_on: "est disponible pour vous sur notre site de test, \nqui vous permettra de découvrir les fonctionnalités de notre plateforme N7 de distribution décentralisée de contenus, adossée à la Blockchain.",
                    click_link: "Pour y accéder, cliquez simplement sur le lien suivant : ",
                    best_regards: "Nous vous remercions d’avoir participé à cette session de test N7 !",
                    views: "Nombre de Vues",
                    income: "Revenu",
                    //actions
                        //general
                    close: "Fermer",
                    upload: "Télécharger",
                    action: "Action",
                    actions: "Actions",
                    submit: "Envoyer",
                    delete: "Supprimer",
                    back_to_page: "retour",
                        //preview
                    preview_content: "Preview",
                        //negotiation
                    accept: "Accepter",
                    accept_proposal: "Accepter l'Offre",
                    reject: "Rejeter",
                    reject_proposal: "Refuser l'Offre",
                    negotiate: "Négocier l'Offre",
                    send_proposal: "Créer une Offre SP",
                    check_proposal: "Voir l'Offre",
                    get_cp_co_negotiation: "Accéder à l'Offre CO",
                    get_cp_sp_negotiation: "Accéder à l'Offre SP",
                        //reply
                    check_co_response: "Voir la Réponse",
                    check_sp_response: "Voir la Réponse",
                    validate: "Valider",
                    validate_proposal: "Valider l'Offre",
                    validate_negotiation: "Valider la Négociation",
                    check_details: "Voir Détails",
                    cancel: "Annuler",
                    cancel_proposal: "Annuler l'Offre",
                    cancel_negotiation: "Annuler la Négociation",
                        //available content
                    content_available_uploaded: "Le contenu est accessible et téléchargé sur le registre distribué.",    
                    content_supplied_uploaded: "Le contenu est mis en distribution et téléchargé sur le registre distribué.",        
                    check_content_on_distributed_ledger: "Vérifier le Contenu sur le Registre",
                        //supplied content
                    broadcast: "Diffuser",
                    broadcast_content: "Diffuser le contenu",
                    handle_viewership: "Gérer la consommation de contenu",
                    mail_sent: "Mails Envoyés",
                        //viewership
                    send_notification: "Envoyer",
                    send_email_to_viewer: "Envoyer une notification au viewer",
                    check_viewership: "Contrôler la Consommation de Contenu",
                    check_proof: "Vérifier la Preuve",
                    delete_proof: "Supprime la Fausse Preuve",
                    check_benefits: "Vérifier les Prestations",
                    update_income: "Actualiser les revenus",
                    //action feedback
                    action_failed: "Action Échouée",
                    field_not_null: "Ce champ est obligatoire.",
                    enter_a_number: "Entrer un chiffre.",
                         //proposal
                    proposal_sent: "Offre Envoyée",
                    cp_reward_between: "La marge aval est compris entre",
                    service_price_between: "Le prix du service du SP est compris entre",
                    and: "et",
                    that_is: "Cela fait",
                    for_cp: "pour le CP et",
                    for_co: "pour",
                    for_sp: "pour",
                    send_proposal_failed: "Envoi de l'Offre Échoué",
                    enter_correct_availability_start_date_msg: "Veuillez entrer une date de début correcte!",
                    enter_correct_availability_end_date_msg: "La date de fin d'accès doit être postérieure à la date de début d'accès!", 
                    enter_correct_service_start_date_msg: "Veuillez entrer une date de début correcte!",
                    enter_correct_service_end_date_msg: "La date de fin de service doit être postérieure à la date de début de service et avant la fin d'accès!",
                        //reply
                    offer_cancelled : "L'offre est annulé",
                    negotiation_accepted: "L'offre est acceptée.",
                    negotiation_cancelled: "La négotiation est annulée.",
                    rejected_proposal_deleted: "L'offre rejetée est supprimée.",
                        //viewership
                    correct_email: "Veuillez entrer une adresse valide!",
                    email_sent_to: "Mail a été envoyé à",
                    valid_proof: "Preuve Validée",
                    fake_proof: "Fausse Preuve",
                    fake_proof_deleted: "La fausse preuve a été supprimée.",
                    loading:"Chargement...",
                    updated: "Actualisé"
                }
            },
            en: {
                translation: {
                    //login
                    admin_login: "Admin Login",
                    company_name: "Company",
                    your_company_name: "Your company name",
                    user_name: "User Name",
                    your_user_name: "Your user name",
                    pwd: "Password",
                    your_pwd: "Your password",
                    email: "Email",
                    your_email: "Your email",
                    sign_in: "Sign In",
                    sign_up: "Sign Up",
                    send_verification_code: "Send verification code",
                    enter_company_name_msg: "Pleasen enter your company name!",
                    enter_user_name_msg: "Please, enter your user name!",
                    enter_user_pwd_msg: "Please, enter your password!",
                    enter_user_email: "Please, enter your email",
                    wrong_company_name_or_wrong_user_name: "Wrong Company Name or Wrong User Name",
                    wrong_user_name_or_wrong_pwd: "Wrong user name or wrong password",
                    wrong_pwd: "Wrong Password",
                    verification_code: "Verification code",
                    //foot
                    about_n7_project: "ABOUT N7 PROJECT",
                    contact_n7: "CONTACT N7",
                    made_by_n7_development_team: "made by N7 development team",
                    //loading 
                    waiting_for_bc_response: "We are waiting for answers from the Blockchain",
                    bc_msg: "Blockchain enables data to be transmitted securely and reliably, and requires a short time to do so.",
                    //errors
                    no_content: "No data to display",
                    no_content_with_filter: "There are no data that correspond to the selected filter.",
                    no_content_without_filter: "There are no data to display.",
                    error_404: "404 Error",
                    page_not_found: "Page not found",
                    retry: "We invite you to try your action again, if the error persists, we invite you to try again later.",
                    bc_error: "An error has occurred on the blockchain network",
                    upload_failed: "Upload failed",
                    //navbar
                    offers: "Offers",
                    diffusions: "Broadcast",
                    state: "State",
                    //content table
                    information: "Information",
                    //users
                    content_owner: "CO",
                    content_provider: "CP",
                    service_provider: "SP",
                    content_owner_full: "Content Owner",
                    content_provider_full: "Content Provider",
                    service_provider_full : "Service Provider",
                    viewer: "Viewer",
                    no_viewer: "No Viewer",
                    viewers: "Viewers",
                    viewer_s: "Viewer(s)",
                    //content
                    contents_on_platform: "Contents on the Platform",
                    no_content_on_platform: "No Content On the Platform",
                    content_s_on_platform: "Content(s) on the Platform",
                    content_previews: "Content Previews",
                    no_preview: "No Preview",
                    content_preview_s: "Content Preview(s)",
                    available_contents: "Available Contents",
                    no_available_content: "No Available Content",
                    available_content_s: "Available Content(s)",
                    supplied_contents: "Supplied Contents",
                    no_supplied_content: "No Supplied Content",
                    supplied_content_s: "Supplied Content(s)",
                    no_benefits: "No Benefits",
                    //content info
                    content_id: "Content ID",
                    content_name: "Content Name",
                    upload_date: "Date of Upload",
                    content_state: "Content State",
                    content_type: "Content Type",
                    ledger_content_info : "Information of the Content on Distributed Ledger",
                    timestamp: "Timestamp",
                    viewership: "Viewership",
                    purchased: "Purchased",
                    not_purchased: "Not Purchased",
                    proof: "Proof",
                    no_proof: "No Proof",
                    proofs: "Proofs",
                    proof_s: "Proof(s)",
                    received: "Received",
                    receive_time_co_cp: "Date of reception of CO offer",
                    valid_time_co_cp: "Date of validation of CO offer",
                    send_time_cp_sp: "Send date of SP offer",
                    valid_time_cp_sp: "Date of validation of SP offer",
                    //content state
                    all: "All",
                    waiting_for_co_proposal: "Waiting For CO's Proposals",
                    under_cp_co_negotiation: "Under CP-CO Negotiations",
                    available: "Available",
                    under_cp_sp_negotiation: "Under CP-SP Negotiations",
                    supplied: "Supplied",
                    contentadded: "Content is uploaded to the blockchain",
                    cpallowed: "CP has the right to deliver the content.",
                    diffusing: "Diffusing",
                    verifying: "Verifying",
                    proof_not_verified: "Proof not Verified",
                    proof_verified: "Proof Verified",
                    //negotiation
                    negotiation_state: "Offer State",
                    no_negotiation: "No Negotiation",
                    negotiation: "Negotiation",
                    negotiations: "Negotiations",
                    negotiation_s: "Negotiation(s)",
                    negotiation_list: "Negotiation List",
                    //proposal
                        //proposal state
                    proposal_accepted: "Proposition Accepted",
                    proposal_rejected: "Proposition Rejected",
                    negotiating_proposal: "Offer Under Negotiation",
                        //proposal info
                    offer : "Offer",
                    content_price: "Content Price",
                    cp_reward: "CP's Reward",
                    volume_commitment: "Volume Commitment",
                    availability_start_date: "Availability Start Date",
                    availability_end_date: "Availability End Date",
                    access_duration: "Access Duration",
                    service_price: "Service Price",
                    service_start_date: "Service Start Date",
                    service_end_date: "Service End Date",
                    delivery_duration: "Delivery Duration",
                    broadcasting_dates: "Broadcasting Dates",
                    year_s: "year(s)",
                    month_s: "month(s)",
                    day_s: "day(s)",
                    message: 'Message',
                    rejection_msg: "Reason for refusal",
                    negotiation_msg: "Negotiation Commentary",
                            //reply
                    response: "Response",
                    response_from_the_content_owner: "Reply from the CO",
                    response_from_the_service_provider: "Reply from the SP",
                                //reply state
                    co_offer_received: "CO Offer Received",
                    waiting_for_sp_reply: "Waiting for SP Reply",
                    no_reply_for_this_proposal: "No Reply for this Offer",
                    accepted: "CO Offer Accepted",
                    rejected: "CO Offer Rejected",
                    negotiating: "CP Offer Negotiating",
                    sp_negotiating: "SP Offer Negotiating",
                    sp_rejected: "SP Offer Rejected",
                    sp_accepted: "SP Offer Accepted",
                    //viewer
                    viewer_email: "Email of the Viewer",
                    notification: "Notification",
                    new_available_content_on_our_website: "A new content is now available on our website!",
                    hello: "Dear N7 user",
                    new_content: "New content",
                    is_now_available: "is now available",
                    is_now_available_on: "is now available on our website, \nwhich enables you to discover the N7 blockchain-based decentralised content distribution platform.",
                    click_link: "Click on the following link to access it!",
                    best_regards: "Thank you for taking part in this N7 test session!",
                    views: "Views",
                    income: "Income",
                    //actions
                        //general
                    action: "Action",
                    actions: "Actions",
                    close: "Close",
                    upload: "Upload",
                    submit: "Submit",
                    delete: "Delete",
                    back_to_page: "return",
                        //preview
                    preview_content: "Preview",
                        //negotiation
                    accept: "Accept",
                    accept_proposal: "Accept Proposal",    
                    reject: "Reject",
                    reject_proposal: "Reject Proposal",
                    negotiate: "Negotiate",
                        //proposal
                    send_proposal: "Send Proposal",
                    check_proposal: "Check Proposal",
                    get_cp_co_negotiation: "Check the CO Offer",
                    get_cp_sp_negotiation: "Check the SP Offer",
                        //reply
                    check_cp_response: "Check CP's Reply",
                    check_sp_response: "Check SP's Reply",
                    validate: "Validate",
                    validate_proposal: "Validate the Offer",
                    validate_negotiation: "Validate the Negotiation",
                    check_details: "Check Details",
                    cancel: "Cancel",
                    cancel_negotiation: "Cancel the negotiaiton",
                        //available content
                    content_available_uploaded: "The content is available and uploaded on the distributed ledger.",
                    content_supplied_uploaded: "The content is supplied and uploaded on the distributed ledger",
                    check_content_on_distributed_ledger: "Check the Content On Distributed Ledger",
                        //supplied content
                    broadcast: "Broadcast",
                    broadcast_content: "Broadcast the Content",
                    handle_viewership: "Handle Viewership",
                    mail_sent: "Mails Sent",
                        //viewership
                    send_notification: "Send",
                    send_email_to_viewer: "Send Notification to Viewer",
                    check_viewership: "Check Viewership",
                    check_proof: "Check the Proof",
                    delete_proof: "Delete the Fake Proof",
                    check_benefits: "Check Benefits",
                    update_income: "Update Income",
                    //action feedback
                    action_failed: "Action Failed",
                    field_not_null: "This field is required.",
                    enter_a_number: "This is not a valid number",
                        //proposal
                    proposal_sent: "Proposal Sent",
                    cp_reward_between: "CP's reward is between",
                    service_price_between: "Service price is between",
                    and: "and",
                    that_is: "That is",
                    for_cp: "for the CP and",
                    for_co: "for",
                    for_sp: "for",
                    send_proposal_failed: "Send Proposal Failed",
                    enter_correct_availability_start_date_msg: "Please fill in a correct availability start date!",
                    enter_correct_availability_end_date_msg: "The availability end date must be later than the availability start date!",
                    enter_correct_service_start_date_msg: "Please fill in a correct service start date!",
                    enter_correct_service_end_date_msg: "The service end date must be later than the service start date and before the availability end date!",
                        //reply
                    offer_cancelled: "The offer is cancelled",
                    negotiation_accepted: "The negotiation is accepted.",
                    negotiation_cancelled: "The negotiation is cancelled.",
                    rejected_proposal_deleted: "The rejected negotiation is deleted.",
                        //viewership
                    correct_email: "Please fill in a correct email address!",
                    email_sent_to: "Mail was sent to ",
                    valid_proof: "Valid Proof",
                    fake_proof: "Fake Proof",
                    fake_proof_deleted: "The fake proof is deleted.",
                    loading:"Loading...",
                    updated: "Updated"
                }
            }
        }
    }
)

export default i18next;