import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import {getDiffusionById} from './ContentManip';
import {calculateContainerHeight} from '../components/ContainerDimensionManip';
import Error1 from '../errorManip/Error1';
import LoadingPage from '../components/LoadingPage';
import { getCpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import cpUserDecoRoutes from '../components/CpUserDecoRoutes';

const CheckDiffusionState = ({translator, filters, cpHash}) => {
    const [deco, setDeco] = useState(null);
    const [contentState, setContentState] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [cpNotExistError, setCpNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const cpData = await getCpByHash(cpHash).then(res => res.data);
            if(cpData!==undefined && cpData.length!==0){
                setDeco(getDeco(cpUserDecoRoutes, cpData[0].cp_name));
            } else {
                setCpNotExistError(true);
            }

            const url = window.location.href;
            const urlArray = url.split("/");
            const contentStateId = urlArray[urlArray.length - 2];
            const contentStateData = await getDiffusionById(contentStateId).then(res => res.data);

            setContentState(contentStateData.length === 0 ? contentStateData : contentStateData[0]);

            const newContentHeight = calculateContainerHeight();
            if (newContentHeight !== contentHeight) {
                setContentHeight(newContentHeight);
            }
        };
        
        init();
    }, []);

    if(contentState !== null){
        if(contentState.length===0 || cpNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else {
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator} 
                        deco={deco}
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters}
                                deco={deco} 
                                returnPath={"/" + cpHash + "/diffusions"}
                                contentName={contentState.content_name}
                            />
                            <div className="form-container" style={{maxHeight: contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_type")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {contentState.content_type}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_price")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {contentState.content_price + " €"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("cp_reward")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {contentState.cp_reward + " €"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_price")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {contentState.service_price + " €"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("broadcasting_dates")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                              <div className="form-text" style={{color: deco.mainColor}}>
                                                { new Date(contentState.service_start_date).toLocaleDateString(localStorage.getItem("i18nextLng")) + 
                                                  " - " +
                                                  new Date(contentState.service_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))
                                                }
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-info-panel">
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_id")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {contentState.content_unique_id}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_owner")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {contentState.content_owner_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_provider")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {contentState.content_provider_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_provider")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {contentState.service_provider_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("receive_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(contentState.received_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("valid_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(contentState.valid_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("send_time_cp_sp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(contentState.received_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("valid_time_cp_sp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(contentState.valid_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <Foot translator={translator } deco={deco} />
                    </div>
                </div>
            )
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default CheckDiffusionState;