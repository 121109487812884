import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import CoFilter from "./CoFilter";
import StateFilter from "./StateFilter";
import SpFilter from "./SpFilter";
import SidebarInfo from "./SidebarInfo";
import {calculateContainerHeight} from "./ContainerDimensionManip";

const Sidebar = ({translator, filters, deco, tag, tagContainerStyle, tagTextStyle, returnPath, contentName, co, cos, cp, sp, sps}) => {

    const [sidebarHeight, setSidebarHeight] = useState("");

    const getCoFilterPage = () => {
        const uri = window.location.pathname;
        const uriArray = uri.split("/");
        const cpHash = uriArray[1];
        const defaultPath = "/" + cpHash;
        if(uri===defaultPath){
            return "offerCoFilter";
        } else {
            const filterPage= uriArray[2];
            switch (filterPage) {
                case "offers":
                    return "offerCoFilter";

                case "diffusions":
                    return "diffusionCoFilter";

                case "revenu":
                    return "revenuCoFilter";
            
                default:
                    break;
            }
        }
    }

    const selectCo = (selectedCo) => {
        const filterPage = getCoFilterPage();
        sessionStorage.setItem(filterPage, selectedCo);
        window.location.reload();
    }

    const getSpFilterPage = () => {
        const uri = window.location.pathname;
        const uriArray = uri.split("/");
        const cpHash = uriArray[1];
        const defaultPath = "/" + cpHash;
        if(uri===defaultPath){
            return "offerSpFilter";
        } else {
            const filterPage= uriArray[2];
            switch (filterPage) {
                case "offers":
                    return "offerSpFilter";

                case "diffusions":
                    return "diffusionSpFilter";

                case "revenu":
                    return "revenuSpFilter";
            
                default:
                    break;
            }
        }
    }

    const selectSp = (selectedSp) => {
        const filterPage = getSpFilterPage();
        sessionStorage.setItem(filterPage, selectedSp);
        window.location.reload();
    }

    const selectState = (selectedState) => {
        sessionStorage.setItem("offerStateFilter", selectedState);
        window.location.reload();
    }

    const activeFilterHandler = (filterType, filterId) => {
        switch (filterType) {
            case "co":
                const filterCoPage = getCoFilterPage();
                const selectedCo = sessionStorage.getItem(filterCoPage);
                return selectedCo===null ? filterId==="all" : filterId===selectedCo;

            case "state":
                const selectedState = sessionStorage.getItem("offerStateFilter");
                return selectedState===null ? filterId==="all" : filterId===selectedState;

            case "sp":
                const filterSpPage = getSpFilterPage();
                const selectedSp = sessionStorage.getItem(filterSpPage);
                return selectedSp===null ? filterId==="all" : filterId===selectedSp;
        
            default:
                break;
        }
    }

    const activeFilterGeneral = (filter, filterName) => {
        return(
            <button id={filter} key={filter} className="selected-tag selected-tag-text" style={{boxShadow : deco.shadowColor, color: deco.mainColor, border: "none"}}>
                { filter==="all" ? translator(filter) : filterName }
            </button>
        )
    }

    const activeFilter = (typeHover, filter) => {
        switch (typeHover) {
            case "co":
                return activeFilterGeneral(filter, filter)
            
            case "state":
                return activeFilterGeneral(filter, translator(filter))
        
            case "sp":
                return activeFilterGeneral(filter, filter)
        
            default:
                break;
        }
    }

    const deactiveFilter = (typeHover, filter) => {
        switch (typeHover) {
            case "co":
                return (
                    <button id={filter} key={filter} className="normal-tag normal-tag-text" style={{color: deco.secondaryColor, border: "none"}} onMouseEnter={e=>{hoverHandler(e)}} onMouseLeave={e=>{unhoverHandler(e)}} onClick={()=>selectCo(filter)}>
                        {filter==="all" ? translator(filter) : filter}
                    </button>
                )
            
            case "state":
                return (
                    <button id={filter} key={filter} className="normal-tag normal-tag-text" style={{color: deco.secondaryColor, border: "none"}} onMouseEnter={e=>{hoverHandler(e)}} onMouseLeave={e=>{unhoverHandler(e)}} onClick={()=>selectState(filter)}>
                        {translator(filter)}
                    </button>
                )

            case "sp":
                return (
                    <button id={filter} key={filter} className="normal-tag normal-tag-text" style={{color: deco.secondaryColor, border: "none"}} onMouseEnter={e=>{hoverHandler(e)}} onMouseLeave={e=>{unhoverHandler(e)}} onClick={()=>selectSp(filter)}>
                        {filter==="all" ? translator(filter) : filter}
                    </button>
                )
        
            default:
                break;
        }
    }

    const hoverHandler = (event) => {
        event.target.style.color = deco.mainColor;
        event.target.classList.add("selected-tag");
        event.target.classList.remove("normal-tag");
        event.target.style.boxShadow = deco.shadowColor;
    }

    const unhoverHandler = (event) => {
        event.target.style.color = deco.secondaryColor;
        event.target.classList.add("normal-tag")
        event.target.classList.remove("selected-tag");
        event.target.style.boxShadow = "unset";
    }

    const filtersHandler = (filter, filterid) => {
        switch (filter) {
            case "co":
                return <CoFilter 
                        key={filterid} 
                        translator={translator} 
                        deco={deco}
                        activeFilterGeneral={activeFilterGeneral}
                        activeFilterHandler={activeFilterHandler} 
                        activeFilter={activeFilter}
                        deactiveFilter={deactiveFilter}
                        hoverHandler={hoverHandler}
                        unhoverHandler={unhoverHandler}
                        cos={cos}
                        selectCo={selectCo}
                        getCoFilterPage={getCoFilterPage}
                    />
                break;
            
            case "state":
                return <StateFilter 
                        key={filterid} 
                        translator={translator} 
                        deco={deco}
                        activeFilterGeneral={activeFilterGeneral}
                        activeFilterHandler={activeFilterHandler} 
                        activeFilter={activeFilter}
                        deactiveFilter={deactiveFilter}
                        hoverHandler={hoverHandler}
                        unhoverHandler={unhoverHandler}
                        selectState={selectState}
                    />
                break;

            case "sp":
                return <SpFilter 
                        key={filterid} 
                        translator={translator} 
                        deco={deco}
                        activeFilterGeneral={activeFilterGeneral}
                        activeFilterHandler={activeFilterHandler} 
                        activeFilter={activeFilter}
                        deactiveFilter={deactiveFilter}
                        hoverHandler={hoverHandler}
                        unhoverHandler={unhoverHandler}
                        sps={sps}
                        selectSp={selectSp}
                        getSpFilterPage={getSpFilterPage}
                    />
                break;
    
            default:
                break;
        }
    }

    const sidebarInfoHandler = () => {
        return(
            <SidebarInfo 
                translator={translator} 
                deco={deco}
                tag={tag} 
                tagContainerStyle={tagContainerStyle} 
                tagTextStyle={tagTextStyle} 
                returnPath={returnPath}
                contentName={contentName}
                co={co}
                cp={cp}
                sp={sp}
            />
        )
    }

    const sidebarHandler = () => {
        var numberOfFilters = filters.length;
        if(numberOfFilters === 0){
            return sidebarInfoHandler();
        } else {
            return filters.map(
                (filter, id) => filtersHandler(filter, id)
            )
        }
    }

    useEffect(() => {
        const sidebarHeight = calculateContainerHeight();
        setSidebarHeight(sidebarHeight);
    }, []);

    return (
        <div className="sidebar-container" style={{maxHeight : sidebarHeight}}>
            {sidebarHandler()}
        </div>
    )
}

export default Sidebar;