import axios from "axios";

import getToken from "./GetToken";

const BASIC_URL="https://vb8q4x5v3e.execute-api.us-east-1.amazonaws.com/v1_0/cp";

const addFirstLoginUserToHF = (user, token) => {
    const specURL = "/cognito-user";
    var url = BASIC_URL + specURL;
    return axios.post(url, user, {
        headers: {
            'Authorization': token
        }
    })
}

const getAvailableContent = (contentId, cpCode) => {
    const specURL="/broadcast/" + cpCode + "/contents/" + contentId;
    const url = BASIC_URL + specURL;
    return axios.get(url, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const addValidCPCONegotiationToBC = (content) => {
    const specURL="/broadcast/access";
    const url = BASIC_URL + specURL;
    return axios.put(url, content, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const addValidCPSPNegotiationToBC = (content) => {
    const specURL="/broadcast/service";
    const url=BASIC_URL + specURL;
    return axios.put(url, content, {
        headers: {
            'Authorization': getToken()
        }
    });
    
}

const addSuppliedContent = (content) => {
    const specURL="/contentAccess/content";
    const url = BASIC_URL + specURL;
    return axios.post(url, content, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const getSuppliedContent = (contentId, cpCode) => {
    const specURL="/contentAccess/" + cpCode + "/contents/" + contentId;
    const url = BASIC_URL + specURL;
    return axios.get(url, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const checkProof = (proof) => {
    const specURL="/contentAccess/proof";
    const url = BASIC_URL + specURL;
    return axios.put(url, proof, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const addViewer = (viewerInfo) => {
    const url=BASIC_URL + "/contentAccess/viewer";
    return axios.put(url, viewerInfo, {
        headers: {
            'Authorization': getToken()
        }
    })
}

const getViewerToken = (viewerInfo) => {
    const viewerId = viewerInfo.viewerId;
    const cpCode = viewerInfo.cpCode;
    const suppliedContentId = viewerInfo.suppliedContentId;
    const url=BASIC_URL + "/contentAccess/" + cpCode + "/token/" + suppliedContentId + "?viewerId=" + viewerId;
    return axios.get(url, {
        headers: {
            'Authorization': getToken()
        }
    });
}

const getAllSuppliedContent = async (cpCode) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
            'Authorization': getToken()
        }
    };
    const url = BASIC_URL + "/contentAccess/" + cpCode + "/contents";
    const res = await fetch(url, requestOptions);
    return res.text();
}

export {addFirstLoginUserToHF, getAvailableContent, addValidCPCONegotiationToBC, addValidCPSPNegotiationToBC, addSuppliedContent, getSuppliedContent, checkProof, addViewer, getViewerToken, getAllSuppliedContent};
