import React from "react";
import { Link } from "react-router-dom";

const SidebarInfo = ({translator, deco, tag, tagContainerStyle, tagTextStyle, returnPath, contentName, co, cp, sp}) => {

    const informationHandler = () => {
        const path = window.location.pathname.toLocaleLowerCase();
        if(path.includes("offer")){
            return(
                <div className="sidebar-info-container">
                    <div className="sidebar-info-item">
                        <div className="sidebar-info-title" style={{color: deco.secondaryColor }}>
                            {contentName}
                        </div>
                    </div>
                    <div className="sidebar-info-item sidebar-info-text" style={{color: deco.secondaryColor, fontSize: 16, fontWeight: "300"}}>
                    </div>
                </div>
            )
        } else if(path.includes("viewership")){
            return(
                <div className="sidebar-info-container">
                    <div className="sidebar-info-item">
                        <div className="sidebar-info-title" style={{color: deco.secondaryColor}}>
                            {translator("content_name")}
                        </div>
                        <div className="sidebar-info-text" style={{color: deco.secondaryColor}}>
                            {contentName}
                        </div>
                    </div>
                    <div className="sidebar-info-item sidebar-info-text" style={{color: deco.secondaryColor, fontSize: 16, fontWeight: "300"}}>
                        {co + " - " + cp + " - " + sp}
                    </div>
                </div>
            )
        } else if(path.includes("checkinfo")){
            return(
                <div className="sidebar-info-container">
                    <div className="sidebar-info-item">
                        <div className="sidebar-info-title" style={{color: deco.secondaryColor}}>
                            {contentName}
                        </div>
                    </div>
                    <div className="sidebar-info-item sidebar-info-text" style={{color: deco.secondaryColor, fontSize: 16, fontWeight: "300"}}>
                        {translator("information")}
                    </div>
                </div>
            )
        }
    }

    const tagHandler = () => {
        const path = window.location.pathname;
        if(path.includes("offers")){
            return(
                <div className={tagContainerStyle}>
                    <div className={tagTextStyle}>{tag}</div>
                </div>
            )
        }
    }


    return (
        <div className="sidebar-info">
            <div>
                <Link  className="sidebar-return-link" to={returnPath}>
                    {translator("back_to_page")}
                </Link>
            </div>
            {informationHandler()}
            {tagHandler()}
        </div>
    )
}

export default SidebarInfo;