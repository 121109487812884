import React, { useState, useEffect } from 'react';
import { Slider } from 'antd';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import {getCoOffer, getSpOffer, acceptOffer, setContentState, updateCpCoUploadStatus, cancelOffer, updateUploadStatus} from './OfferManip';
import {hoverSubmitHandler, unhoverSubmitHandler, hoverCancelHandler, unhoverCancelHandler} from '../components/ButtonManip';
import {addValidCPCONegotiationToBC, addValidCPSPNegotiationToBC, addSuppliedContent} from '../blockchainManip/BCManip';
import {calculateContainerHeight} from '../components/ContainerDimensionManip';
import LoadingButton from '../components/LoadingButton';
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import LoadingPage from '../components/LoadingPage';
import { getCpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import cpUserDecoRoutes from '../components/CpUserDecoRoutes';

const CheckOfferState = ({translator, filters, cpHash}) => {
    const [deco, setDeco] = useState(null);
    const [coOrSpOffer, setCoOrSpOffer] = useState("");
    const [offer, setOffer] = useState(null);
    const [successionNegotiation, setSuccessionNegotiation] = useState(null);
    const [contentPriceList, setContentPriceList] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [contentHeight, setContentHeight] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [cpNotExistError, setCpNotExistError] = useState(false);

    const getCoOrSpOffer = async (coOrSpOffer, offerId) => {
        switch (coOrSpOffer) {
            case "co":
                return await getCoOffer(offerId).then(res => res.data);

            case "sp":
                return await getSpOffer(offerId).then(res => res.data);
        
            default:
                break;
        }
    }

    useEffect(() => {
        const init = async () => {
            const cpData = await getCpByHash(cpHash).then(res => res.data);
            if(cpData!==undefined && cpData.length!==0){
                setDeco(getDeco(cpUserDecoRoutes, cpData[0].cp_name));
            } else {
                setCpNotExistError(true);
            }

            const url = window.location.href;
            const urlArray = url.split("/");
            const offerId = urlArray[urlArray.length - 2];
            const coOrSpOffer = urlArray[urlArray.length - 3];
            const offerData = await getCoOrSpOffer(coOrSpOffer, offerId);

            if(offerData.length !== 0){
                if(coOrSpOffer==="sp"){
                    const dbSuccessionNegotiation = await getCoOffer(offerData[0].succession_negotiation).then(res => res.data[0]);
                    setSuccessionNegotiation(dbSuccessionNegotiation);
                }
            }
  
            setOffer(offerData.length === 0 ? offerData : offerData[0]);
            setCoOrSpOffer(coOrSpOffer);
            setContentPriceList([{type: "Standard", contentPrice: 1.5, n7Reward: 0.23}, {type: "Premium", contentPrice: 2, n7Reward: 0.5}, {type: "Ultra Premium", contentPrice: 3, n7Reward: 0.78}]);

            const contentHeightValue = calculateContainerHeight();
            if(contentHeightValue !== contentHeight){
                setContentHeight(contentHeightValue);
            }
        };
  
        init();
    }, []);
    
    const tagContainerStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return coOrSpOffer==="co" ? "valid-tag" : "info-tag"

            case "accepted" : 
                return "valid-tag"

            case "sp_accepted":
                return "valid-tag"
        
            default:
                return "info-tag";
        }
    }

    const tagTextStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return coOrSpOffer==="co" ? "valid-tag-text" : "info-tag-text"

            case "accepted" : 
                return "valid-tag-text"

            case "sp_accepted":
                return "valid-tag-text"
        
            default:
                return "info-tag-text";
        }
    }

    const cpRewardHandler = () => {
        return(
            <div className="form-horizon-sub-item">
                <div className="form-text" style={{color: deco.mainColor}}>
                    {offer.cp_reward + "€"}
                </div>
            </div>
        )
    }

    const validDate = (serviceStartDate, serviceEndDate) => {
        const startDate = new Date(serviceStartDate);
        const endDate = new Date(serviceEndDate);
        const today = new Date();
        today.setHours(0,0,0,0);
        startDate.setHours(0,0,0,0);
        endDate.setHours(0,0,0,0);
        var validateStatus = true;
        if(startDate.getTime() > today.getTime() || endDate.getTime() < today.getTime()) {
            validateStatus = false;
        }
        return validateStatus;
    }

    const acceptOfferHandler = (offerId) => {
        setShowLoader(true);
        const contentId = offer.content_unique_id;
        const contentOwner = offer.content_owner;
        const contentProvider = offer.content_provider;
        const coName = offer.content_owner_name;
        const cpName = offer.content_provider_name;
        const contentType = offer.content_type;
        const contentPrice = offer.content_price;
        const cpReward = offer.cp_reward;
        const availabilityStartDate = offer.availability_start_date;
        const availabilityEndDate = offer.availability_end_date;
        const accessDuration = offer.access_duration;
        const volumeCommitment = offer.volume_commitment;

        const negotiationResult = {
            cpCode: getCpCode(cpName),
            contentId: getContentCode(contentId, cpName),
            owner: coName,
            contentType: contentType,
            availableContentPrice: contentPrice.toString(),
            cpReward: cpReward.toString(),
            availabilityStartDate: availabilityStartDate,
            availabilityEndDate: availabilityEndDate,
            accessDuration: accessDuration,
            cp: cpName,
            volumeCommitment: volumeCommitment.toString()
        }
        addValidCPCONegotiationToBC(negotiationResult).then(() => {
            const validTime = new Date();
            const validUTCTime = new Date(setUTCDate(validTime)).toISOString();
        
            const offerAccepted = {
                validTime: validUTCTime,
                offerId: offerId
            }
            acceptOffer(offerAccepted).then(() => {
                const contentState = {
                    contentId: contentId,
                    contentOwner: contentOwner,
                    contentProvider: contentProvider,
                    cpCoNegotiation: offerId,
                    cpManager: JSON.parse(sessionStorage.getItem("user")).cpUserName
                }
                setContentState(contentState).then(() => {
                    updateCpCoUploadStatus(offerId).then(() => {
                        setShowLoader(false);
                        alert(translator("proposal_accepted"));
                        window.location.assign("/" + cpHash + "/offers")
                    }).catch(() => {
                        setShowLoader(false);
                        alert(translator("action_failed"))
                    })
                }).catch(() => {
                    setShowLoader(false);
                    alert(translator("action_failed"))
                })
            }).catch(() => {
                setShowLoader(false);
                alert(translator("action_failed"))
            })
        }).catch((err) => {
            setShowLoader(false);
            setBcErrorOccured(true);
            setBcErr({name: err.name, msg: err.request.response});
        })
    }

    const cancelOfferHandler = (offerId) => {
        cancelOffer(offerId).then(() => {
            if(offer.negotiate_on!==null){
                cancelOffer(offer.negotiate_on).then(() => {
                    alert(translator("offer_cancelled"));
                    window.location.assign("/" + cpHash + "/offers");
                }).catch(() => {
                    alert(translator("action_failed"))
                })
            }
            alert(translator("offer_cancelled"));
            window.location.assign("/" + cpHash + "/offers");
        }).catch(() => {
            alert(translator("action_failed"))
        })
    }

    const setUTCDate = (date) => {
        const dateFormatDate = new Date(date);
        return Date.UTC(dateFormatDate.getUTCFullYear(), 
                        dateFormatDate.getUTCMonth(), 
                        dateFormatDate.getUTCDate(), 
                        dateFormatDate.getUTCHours(),
                        dateFormatDate.getUTCMinutes(),
                        dateFormatDate.getUTCSeconds());
    }

    const diffusionHandler = async (event) => {
        event.preventDefault();
        event.target.style.display = "unset";
        setShowLoader(true);
        
        var uploadResult = true;
        const contentId = offer.content_unique_id;
        const cpName = offer.content_provider_name;
        const contentName = offer.content_name;
        const coName = offer.content_owner_name;
        const contentType = successionNegotiation.content_type;
        const contentPrice = successionNegotiation.content_price.toString();
        const cpReward = successionNegotiation.cp_reward.toString();
        const spName = offer.service_provider_name;
        const servicePrice = offer.service_price.toString();
        const serviceStartDate = offer.service_start_date;
        const serviceEndDate = offer.service_end_date;
        const deliveryDuration = offer.delivery_duration;
        const volumeCommitment = offer.volume_commitment.toString();

        let negotiationResult = {
            cpCode: getCpCode(cpName),
            contentId: getContentCode(contentId, cpName)
        }
        addValidCPSPNegotiationToBC(negotiationResult).then(() => {
            let suppliedContent = {
                cpCode: getCpCode(cpName),
                contentId: getContentCode(contentId, cpName),
                contentName: contentName,
                owner: coName,
                contentType: contentType,
                contentPrice: contentPrice,
                cp: cpName,
                cpReward: cpReward,
                sp: spName,
                servicePrice: servicePrice,
                serviceStartDate: serviceStartDate,
                serviceEndDate: serviceEndDate,
                deliveryDuration: deliveryDuration,
                volumeCommitment: volumeCommitment
            }
            addSuppliedContent(suppliedContent).then(() => {
                updateUploadStatus(offer.negotiation_id).then(() => {
                    setShowLoader(false);
                    alert(offer.content_name + " " + translator("is_now_available"));
                    window.location.reload();
                }).catch(() => {
                    setShowLoader(false);
                    uploadResult=false;
                    alert(translator("action_failed"))
                })
            }).catch(err => {
                uploadResult=false;
                setShowLoader(false);
                setBcErrorOccured(true);
                setBcErr({name: err.name, msg: err.request.response});
            })
        }).catch(err => {
            uploadResult=false;
            setShowLoader(false);
            setBcErrorOccured(true);
            setBcErr({name: err.name, msg: err.request.response});
        })
        return uploadResult;
    }

    const NotUploadedToBC = () => {
        return offer.uploaded_to_bc===0;
    }

    const actionHandler = (valid, offerId) => {
        switch (valid) {
            case "":
                return coOrSpOffer==="co" ?
                    <div className="form-button-panel">
                        <LoadingButton 
                            text={translator("validate_proposal")} 
                            color={deco.secondaryColor} 
                            onSubmit={()=>{acceptOfferHandler(offerId)}} 
                            loading={showLoader} 
                            disabled={showLoader} 
                        /> 
                    </div> : 
                    <div className="form-button-panel">
                        <button className="n7-button cancel-button" onMouseEnter={e=>{hoverCancelHandler(e)}} onMouseLeave={e=>{unhoverCancelHandler(e)}} onClick={()=>{cancelOfferHandler(offerId)}}>{translator("cancel_proposal")}</button>
                    </div>

            case "accepted" : 
                return (
                    <div className="form-button-panel">
                        <button className="n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} onClick={()=>{window.location.assign("/" + cpHash + "/offers/" + offer.negotiation_id + "/sendOffer")}} style={{color: deco.secondaryColor}}>{translator("send_proposal")}</button>
                    </div> 
                )

            case "sp_accepted" : 
                return (
                    <div className="form-button-panel">
                        {
                            NotUploadedToBC() && validDate(offer.service_start_date, offer.service_end_date) ? 
                            <LoadingButton 
                                text={translator("broadcast_content")} 
                                onSubmit={(event) => diffusionHandler(event)} 
                                color={deco.secondaryColor}  
                                loading={showLoader} 
                                disabled={showLoader}
                            /> : 
                            <button disabled className="n7-button normal-button disabled-button" style={{color: deco.secondaryColor, opacity: "0.5"}}>{translator("broadcast_content")}</button>
                        }
                    </div>
                )

            default:
                return (
                    <></>
                );
        }
    }

    const spOfferHandler = () => {
        return (
            <>
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("send_time_cp_sp")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {new Date(offer.received_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                    </div>
                </div>
                {offer.negotiation_valid==="sp_accepted" ?
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("valid_time_cp_sp")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {new Date(offer.valid_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                    </div>
                </div> : <></>
                }
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("content_owner")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {offer.content_owner_name}
                    </div>
                </div>
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("availability_start_date")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {new Date(successionNegotiation.availability_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                    </div>
                </div>
                <div className="form-info-item">
                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                        {translator("availability_end_date")}
                    </div>
                    <div className="form-info-text" style={{color: deco.secondaryColor}}>
                        {new Date(successionNegotiation.availability_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                    </div>
                </div>
            </>
        )
    }

    const getSliderInfo = () => {
        const priceInfoArray = coOrSpOffer==="co" ? contentPriceList.filter((array) => array.type===offer.content_type) : contentPriceList.filter((array) => array.type===successionNegotiation.content_type);
        const n7Reward = priceInfoArray[0].n7Reward;
        const contentPrice = priceInfoArray[0].contentPrice;
        const cpReward = coOrSpOffer==="co" ? 0 : successionNegotiation.cp_reward;
        const maxMark = coOrSpOffer==="co" ? (contentPrice - n7Reward).toFixed(2) : Number(cpReward).toFixed(2);
        
        return {
            maxMark: maxMark,
            sliderMarks : {
                0: {
                    style: {
                        color: deco.secondaryColor,
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 500
                    }, 
                    label: 0 + "€"
                }
            }
        }
    }

    if(offer !== null){
        if(offer.length===0 || cpNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else if(bcErrorOccured){
            return (
                <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
            )
        } else {
            const sliderBorderColor = "0 0 0 2px " + deco.mainColor;
            const sliderShadowColor = "0 0 0 4px " + deco.mainColor;
            const sliderInfo = getSliderInfo();
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                tag={offer.negotiation_valid===null ?( coOrSpOffer==="co" ? translator("co_offer_received") : translator("waiting_for_sp_reply")) : translator(offer.negotiation_valid)} 
                                tagContainerStyle={tagContainerStyleHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)}
                                tagTextStyle={tagTextStyleHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)}
                                returnPath={"/" + cpHash + "/offers"}
                                contentName={offer.content_name}
                            />
                            <div className="form-container"  style={{maxHeight : contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {coOrSpOffer==="co" ? translator("content_owner") : translator("service_provider")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <button 
                                                    className="selected-tag selected-tag-text form-disable-text" 
                                                    style={{border: "none", color: deco.mainColor, boxShadow: deco.shadowColor}}
                                                    disabled
                                                >
                                                    {coOrSpOffer==="co" ? 
                                                        offer.content_owner_name : 
                                                        offer.service_provider_name
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {coOrSpOffer==="co" ? translator("cp_reward") : translator("service_price")}
                                            </div>
                                            { coOrSpOffer==="co" ? 
                                                cpRewardHandler(offer.negotiation_valid, sliderInfo.maxMark, sliderInfo.sliderMarks, sliderBorderColor, sliderShadowColor) : 
                                                <div style={{width: "100%", display: "inline-flex", flexDirection: "row", gap: "8px"}}>
                                                    <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>0</div>
                                                    <Slider
                                                        style={{width:"100%", "--slider-border-color": sliderBorderColor, "--slider-shadow-color": sliderShadowColor}}
                                                        defaultValue={0}
                                                        disabled
                                                        min={0.00} max={sliderInfo.maxMark} marks={sliderInfo.sliderMarks} step={0.01}
                                                    />
                                                    <div style={{color: deco.secondaryColor, fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "500"}}>{sliderInfo.maxMark + "€"}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {coOrSpOffer==="co" ? translator("availability_start_date") : translator("service_start_date")}
                                            </div>
                                            <div className="form-horizon-sub-item form-disable-text">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {
                                                        coOrSpOffer==="co" ? 
                                                        new Date(offer.availability_start_date).toLocaleDateString(localStorage.getItem("i18nextLng")) :
                                                        new Date(offer.service_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {coOrSpOffer==="co" ? translator("availability_end_date") : translator("service_end_date")}
                                            </div>
                                            <div className="form-horizon-sub-item form-disable-text">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {
                                                        coOrSpOffer==="co" ?
                                                        new Date(offer.availability_end_date).toLocaleDateString(localStorage.getItem("i18nextLng")) : 
                                                        new Date(offer.service_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-info-panel">
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_id")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {offer.content_unique_id}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{ color: deco.secondaryColor}}>
                                                {translator("content_type")}
                                            </div>
                                            <div className="form-info-text" style={{ color: deco.secondaryColor}}>
                                                {coOrSpOffer==="co" ? offer.content_type : successionNegotiation.content_type}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{ color: deco.secondaryColor }}>
                                                {translator("receive_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{ color: deco.secondaryColor }}>
                                                {coOrSpOffer==="co" ? new Date(offer.received_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng")) : new Date(successionNegotiation.received_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        { coOrSpOffer==="co" ? 
                                            ( offer.negotiation_valid==="accepted" ? 
                                                <div className="form-info-item">
                                                    <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                        {translator("valid_time_co_cp")}
                                                    </div>
                                                    <div className="form-info-text" style={{ color: deco.secondaryColor }}>
                                                        {new Date(offer.valid_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                    </div>
                                                </div> : <></> 
                                            ) :
                                            <div className="form-info-item">
                                                <div className="form-info-label" style={{ color: deco.secondaryColor }}>
                                                    {translator("valid_time_co_cp")}
                                                </div>
                                                <div className="form-info-text" style={{ color: deco.secondaryColor }}>
                                                    { new Date(successionNegotiation.valid_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                        }
                                        {coOrSpOffer==="sp" ? spOfferHandler() : ""}
                                    </div>
                                </div>
                                {/* {messageHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)} */}
                                {actionHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid, offer.negotiation_id)}    
                            </div>
                        </div>
                        <Foot translator={translator } deco={deco} />
                    </div>    
                </div>
            )
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default CheckOfferState;