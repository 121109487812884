import axios from "axios";

const BASIC_URL="https://n7-demo-content-provider.nseven.xyz/api/v1/cp";

const getContentStateIdForNotification = (negotiationId) => {
    const url=BASIC_URL + "/diffusions/contentStateId/" + negotiationId;
    return axios.get(url);
}

const getAllDiffusion = (cpHash) => {
    const url=BASIC_URL + "/diffusions/" + cpHash + "/all";
    return axios.get(url);
}

const getAllUploadedDiffusion = (cpHash) => {
    const url=BASIC_URL + "/diffusions/" + cpHash + "/uploaded";
    return axios.get(url);
}

const getDiffusionById = (diffusionId) => {
    const url=BASIC_URL + "/diffusions/contentState/" + diffusionId;
    return axios.get(url);
}

const getMail = (contentStateId) => {
    const url=BASIC_URL + "/diffusions/" + contentStateId + "/mails";
    return axios.get(url);
}

const saveMail = (contentViewerMail) => {
    const url=BASIC_URL + "/diffusions/mails";
    return axios.post(url, contentViewerMail);
}

const getUrlSpec = (contentStateId, viewerMail) => {
    const encodedEmail = encodeURIComponent(viewerMail);
    const url = BASIC_URL + "/diffusions/" + contentStateId + "/mail/" + encodedEmail;
    return axios.get(url);
}

const getViewerId = (contentViewerMail) => {
    const encodedEmail = encodeURIComponent(contentViewerMail);
    const url=BASIC_URL + "/diffusions/viewer/" + encodedEmail +  "/viewerId";
    return axios.get(url);
}

const updatePurchaseState = (contentViewerMail) => {
    const url=BASIC_URL + "/diffusions/mails";
    return axios.put(url, contentViewerMail);
}

export {getContentStateIdForNotification, getAllDiffusion, getAllUploadedDiffusion, getDiffusionById, getMail, saveMail, getUrlSpec, getViewerId, updatePurchaseState};
