const cpUserDecoRoutes = [
    {
        id: 1,
        name: "OM",
        logoPath: "om.png",
        mainColor: "#2FAEE0",
        secondaryColor: "#014D6B",
        shadowColor: "0px 0px 6px #2FAEE04D"
    },
    {
        id: 2,
        name: "PSG",
        logoPath: "psg.png",
        mainColor: "#3581BE",
        secondaryColor: "#00305C",
        shadowColor: "0px 0px 6px #3581BE4D"
    },
    {
        id: 3,
        name: "Real de Madrid",
        logoPath: "rdm.png",
        mainColor: "#FBC700",
        secondaryColor: "#2B299B",
        shadowColor: "0px 0px 6px #FBC7004D"
    },
    {
        id: 4,
        name: "FC Barcelone",
        logoPath: "fcb.png",
        mainColor: "#BF5905",
        secondaryColor: "#283498",
        shadowColor: "0px 0px 6px #BF59054D"
    },
    {
        id: 5,
        name: "Sud Ouest",
        logoPath: "sudouest.png",
        mainColor: "#D42A1A",
        secondaryColor: "#000000",
        shadowColor: "0px 0px 6px #D42A1A4D"
    }
];
export default cpUserDecoRoutes;
