const calculateContainerHeight = () =>{
	const contentContainer = document.getElementsByClassName("wrapper");
	const header = document.getElementsByClassName("navbar");
	const footer = document.getElementsByClassName("footer");
	
    if(contentContainer.length!==0){
		const paddingHeaderContainer = 164 - header[0].offsetHeight;
        const height = contentContainer[0].offsetHeight - header[0].offsetHeight - paddingHeaderContainer  - footer[0].offsetHeight;
		return height + "px";
	}
}

const calculateTableWidth = (columnName) => {
    const query = "." + columnName;
    const columnItems = document.querySelectorAll([query]);
    
    var maxWidth = 0;
    for (let i = 0; i < columnItems.length; i++) {
        const item = columnItems[i];
        if(item.offsetWidth > maxWidth){
            maxWidth = item.offsetWidth;
        }
    }
    return maxWidth;
}

export {calculateContainerHeight, calculateTableWidth};