import React, {useState} from "react";
import { Nav, Dropdown, DropdownToggle, DropdownMenu,DropdownItem} from "reactstrap";
import languages from "../internationalization/languages";
import i18next from "i18next";

const LoginNavbar = () => {

    const [languageSelectionOpened, setLanguageSelectionOpened] = useState(false);

    const languageSelectionHandler = () => {
        setLanguageSelectionOpened(!languageSelectionOpened);
    }

    return (
       <div className="login-navbar">
           <Nav navbar className="login-navbar-right-panel">
               <Dropdown nav isOpen={languageSelectionOpened} toggle={languageSelectionHandler}>
                   <DropdownToggle nav>
                       <img className="login-navbar-language-selector" src={require("./icons/globe.png")} alt="nav-globe" />
                   </DropdownToggle>
                   <DropdownMenu end>
                       {
                           Object.keys(languages).map(
                               language => (
                                   <DropdownItem key={ language } onClick={() => i18next.changeLanguage(language) } > 
                                       { languages[language].name } 
                                   </DropdownItem>
                               )
                           )
                       }
                   </DropdownMenu>
               </Dropdown>
           </Nav>
       </div>
    )
}

export default LoginNavbar;