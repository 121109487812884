const getDeco = (cpUserDecoRoutes, cpName) => {
    const foundCp = cpUserDecoRoutes.find(cp => cp.name === cpName);

    return foundCp ? {
        logoPath : foundCp.logoPath,
        mainColor : foundCp.mainColor,
        secondaryColor : foundCp.secondaryColor,
        shadowColor : foundCp.shadowColor
    } : null;
}

export default getDeco;