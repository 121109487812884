import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import {getCoList, getSpList} from '../offers/OfferManip';
import {getSuppliedContent, checkProof} from '../blockchainManip/BCManip';
import {hoverSubmitHandler, unhoverSubmitHandler} from '../components/ButtonManip';
import {getAllUploadedDiffusion} from '../diffusions/ContentManip';
import LoadingButton from '../components/LoadingButton';
import NoContent from '../components/NoContent';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import BCLoadingPage from '../components/BCLoadingPage';
import { getCpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import cpUserDecoRoutes from '../components/CpUserDecoRoutes';
import Error1 from '../errorManip/Error1';

const Revenu = ({translator, filters, cpHash}) => {
    const [deco, setDeco] = useState(null);
    const [revenu, setRevenu] = useState(null);
    const [cos, setCos] = useState([]);
    const [sps, setSps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableCoWidth, setTableCoWidth] = useState("");
    const [tableSpWidth, setTableSpWidth] = useState("");
    const [tableContentPriceWidth, setTableContentPriceWidth] = useState("");
    const [tableCpRewardWidth, setTableCpRewardWidth] = useState("");
    const [tableServicePriceWidth, setTableServicePriceWidth] = useState("");
    const [tableViewerWidth, setTableViewerWidth] = useState("");
    const [tableIncomeWidth, setTableIncomeWidth] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [cpNotExistError, setCpNotExistError] = useState(false);

    const completeRevenuArray = (revenuArray) => {
            for (let i = 0; i < revenuArray.length; i++) {
            var revenu = revenuArray[i];
            if(revenu !== undefined){
                revenu = {
                    ...revenu,
                    info: [
                        {
                            label: "identifiant",
                            value: revenu.suppliedContentId
                        }
                    ]
                }
                revenuArray[i] = revenu;
            }
            
        }
        return revenuArray;
    }

    const setCoFilter = (revenu) => {
        var selectedCo = sessionStorage.getItem("revenuCoFilter");
        if(selectedCo!==null && selectedCo!=="all"){
            revenu = revenu.filter((r) => r.suppliedContentOwner===selectedCo);
        }
        return revenu;
    }

    const setSpFilter = (revenu) => {
        // var selectedSp = sessionStorage.getItem("revenuSpFilter");
        // if(selectedSp!==null && selectedSp!=="all"){
        //     revenu = revenu.filter((r) => r.suppliedContentServiceProvider===selectedSp);
        // }
        revenu = revenu.filter((r) => r.suppliedContentServiceProvider==="Sport All");
        return revenu;
    }

    const countViewers = (viewersArray) => {
        viewersArray = viewersArray.filter((viewer) => viewer.viewerId!=="");
        var viewers = [...new Set(viewersArray.map(viewer => viewer.viewerId))];
        return viewers.length;
    }

    const countViews = (proofsArray) => {
        var validViews = 0;
        for (let i = 0; i < proofsArray.length; i++) {
            const proof = proofsArray[i];
            if(proof.validation==="valid"){
                validViews+=1;
            }
        }
        return validViews;
    }

    const calculateIncome = (proofsArray, cpReward, servicePrice) => {
        var validViews = 0;
        for (let i = 0; i < proofsArray.length; i++) {
            const proof = proofsArray[i];
            if(proof.validation==="valid"){
                validViews+=1;
            }
        }
        const singlePrice = cpReward - servicePrice;
        var incomeValue = (validViews * singlePrice).toFixed(2);
        return Number(incomeValue).toFixed(2)  + " €";
    }

    const checkProofHandler = async (proof) => {
        var result = true;
        const verificationResult = await checkProof(proof).then(res => res.data).catch((err) => {setBcErrorOccured(true); setBcErr({name: err.name, msg: err.request.response})});
        if(verificationResult!=="Valid proof"){
            result = false;
        }
        return result;
    }

    const checkAllProof = async (suppliedContents) => {
        var result = true;
        var verificationArray = [];
        for (let i = 0; i < suppliedContents.length; i++) {
            const suppliedContent = suppliedContents[i];
            const suppliedContentId = suppliedContent.suppliedContentId;
            const viewerships = suppliedContent.viewershipVerification;
            for (let j = 0; j < viewerships.length; j++) {
                const viewership = viewerships[j];
                const viewer = viewership.viewer;
                if(viewership.validation===""){
                    const nonce = viewership.nonce;
                    let proof = {
                        cpCode: getCpCode(suppliedContent.suppliedContentContentProvider),
                        suppliedContentId: suppliedContentId,
                        viewerId: viewer,
                        nonce: nonce,
                        encryptedNonce: viewership.viewershipProof
                    }
                    verificationArray.push(checkProofHandler(proof));
                }
            }
        }
        const verificationResult = await Promise.all(verificationArray);
        
        if(verificationResult.includes(false)){
            result = false
        }
        return result;
    }

    const update = async () => {
        setShowLoader(true);
        const suppliedContents = revenu;
        const result = await checkAllProof(suppliedContents);

        setShowLoader(false);

        if(result===true){
            alert(translator("updated"));
            window.location.reload();
        } else {
            alert(translator("action_failed"));
            window.location.reload();
        }
    }

    useEffect(() => {
        const init = async () => {
            const cpData = await getCpByHash(cpHash).then(res => res.data);
            if(cpData!==undefined && cpData.length!==0){
                setDeco(getDeco(cpUserDecoRoutes, cpData[0].cp_name));
            } else {
                setCpNotExistError(true);
            }


            const coList = await getCoList().then(res => res.data);
            setCos(coList);

            const spList = await getSpList().then(res => res.data);
            setSps(spList);

            const suppliedContents = await getAllUploadedDiffusion(cpHash).then(res => res.data);

            var allSuppliedContents = [];

            for (let i = 0; i < suppliedContents.length; i++) {
                const content = suppliedContents[i];
                const contentIdCode = getContentCode(content.content_unique_id, content.content_provider_name);
                const cpCode = getCpCode(content.content_provider_name);
                var bcContent = await getSuppliedContent(contentIdCode, cpCode).then(res => res.data).catch((err) => {setBcErrorOccured(true); setBcErr({name: err.name, msg: err.request.response})});
                bcContent = {...bcContent, compareDate: content.valid_time_cp_sp}
                allSuppliedContents = [...allSuppliedContents, bcContent];
            }

            // just for preprod
            allSuppliedContents = completeRevenuArray(allSuppliedContents);
            allSuppliedContents = setCoFilter(allSuppliedContents);
            allSuppliedContents = setSpFilter(allSuppliedContents);

            allSuppliedContents.sort((contentA, contentB) => {
                var dateA = contentA.compareDate;
                var dateB = contentB.compareDate;
                return new Date(dateB) - new Date(dateA)
            })

           setRevenu(allSuppliedContents);

            const contentHeightValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name")===0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableCoWidthValue = calculateTableWidth("table-co")===0 ? "auto" : calculateTableWidth("table-co") + "px";
            const tableSpWidthValue = calculateTableWidth("table-sp")===0 ? "auto" : calculateTableWidth("table-sp") + "px";
            const tableContentPriceWidthValue = calculateTableWidth("table-content-price")===0 ? "auto" : calculateTableWidth("table-content-price") + "px";
            const tableCpRewardWidthValue = calculateTableWidth("table-cp-reward")===0 ? "auto" : calculateTableWidth("table-cp-reward") + "px";
            const tableServicePriceWidthValue = calculateTableWidth("table-service-price")===0 ? "auto" : calculateTableWidth("table-service-price") + "px";
            const tableViewerWidthValue = calculateTableWidth("table-viewer")===0 ? "auto" : calculateTableWidth("table-viewer") + "px";
            const tableIncomeWidthValue = calculateTableWidth("table-income")===0 ? "auto" : calculateTableWidth("table-income") + "px";
            if(contentHeightValue!==contentHeight || tableContentNameWidthValue!==tableContentNameWidth || tableCoWidthValue!==tableCoWidth || tableSpWidthValue!==tableSpWidth || tableContentPriceWidthValue!==tableContentPriceWidth || tableCpRewardWidthValue!==tableCpRewardWidth || tableServicePriceWidthValue!==tableServicePriceWidth || tableViewerWidthValue!==tableViewerWidth || tableIncomeWidthValue!==tableIncomeWidth){
                setContentHeight(tableContentNameWidthValue);
                setTableContentNameWidth(tableCoWidthValue);
                setTableCoWidth(tableCoWidthValue);
                setTableSpWidth(tableSpWidthValue);
                setTableContentPriceWidth(tableContentPriceWidthValue);
                setTableCpRewardWidth(tableCpRewardWidthValue);
                setTableServicePriceWidth(tableServicePriceWidthValue);
                setTableViewerWidth(tableViewerWidthValue);
                setTableIncomeWidth(tableIncomeWidthValue);
            }
        };

        init();
    }, []);

    if(revenu!==null){
        if(cpNotExistError){
            return <Error1 translator={translator} />
        } else if(bcErrorOccured){
            return <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
        } else if(revenu.length!==0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="calculer-income-btn">
                            <LoadingButton 
                                text={translator("update_income")} 
                                className="n7-button normal-button" 
                                onSubmit={()=>{update()}} 
                                color={deco.secondaryColor} 
                                loading={showLoader} 
                                disabled={showLoader} 
                            />
                        </div>
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cos={cos}
                                sps={sps} 
                            />
                            <div className="table-container" style={{maxHeight: contentHeight}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}>{translator("content_name")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableCoWidth}}>{translator("content_owner")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableSpWidth}}>{translator("service_provider")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableContentPriceWidth}}>{translator("content_price")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableCpRewardWidth}}>{translator("cp_reward")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableServicePriceWidth}}>{translator("service_price")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableViewerWidth}}>{translator("viewer")  + "/" + translator("views")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableIncomeWidth}}>{translator("income")}</th>
                                            <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {revenu.map((revenu, id) => 
                                            <tr key = {id} >
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-content-name">{revenu.suppliedContentName}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-co">{revenu.suppliedContentOwner}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-sp">{revenu.suppliedContentServiceProvider}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-content-price">{revenu.suppliedContentPrice + " €"}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-cp-reward">{revenu.suppliedContentCpReward + " €"}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-service-price">{revenu.servicePrice + " €"}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-viewer">{countViewers(revenu.viewerSubscription) + "/" + countViews(revenu.viewershipVerification)}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-income">{calculateIncome(revenu.viewershipVerification, revenu.suppliedContentCpReward, revenu.servicePrice)}</p></td>
                                                <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                    <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={revenu.info} /> </span>
                                                    <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        } else if(revenu.length===0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cos={cos}
                                sps={sps} 
                            />
                            <NoContent translator={translator} />
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else {
        return(
            <BCLoadingPage translator={translator} />
        )
    }
}

export default Revenu;