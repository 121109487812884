import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import emailjs from "@emailjs/browser";
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import {hoverSubmitHandler, unhoverSubmitHandler, hoverCancelHandler, unhoverCancelHandler} from '../components/ButtonManip';
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import {getAllDiffusion} from './ContentManip';
import {getCoList, updateUploadStatus} from '../offers/OfferManip';
import {addValidCPSPNegotiationToBC, addSuppliedContent} from '../blockchainManip/BCManip';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import LoadingButton from '../components/LoadingButton';
import NoContent from '../components/NoContent';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import LoadingPage from '../components/LoadingPage';
import { getCpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import cpUserDecoRoutes from '../components/CpUserDecoRoutes';
import Error1 from '../errorManip/Error1';

const Diffusions = ({translator, filters, cpHash}) => {
    const [deco, setDeco] = useState(null);
    const [diffusions, setDiffusions] = useState(null);
    const [cos, setCos] = useState([]);
    const [sps, setSps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableCoWidth, setTableCoWidth] = useState("");
    const [tableSpWidth, setTableSpWidth] = useState("");
    const [tableDatesWidth, setTableDatesWidth] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [cpNotExistError, setCpNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const cpData = await getCpByHash(cpHash).then(res => res.data);
            if(cpData!==undefined && cpData.length!==0){
                setDeco(getDeco(cpUserDecoRoutes, cpData[0].cp_name));
            } else {
                setCpNotExistError(true);
            }

            var diffusionsData = await getAllDiffusion(cpHash).then(res => res.data);
            
            const coList = await getCoList().then(res => res.data);
            setCos(coList);
            const spList = [{sp_id: 6, sp_name: "Sport All"}];
            setSps(spList);

            if(diffusionsData.length!==0){
                diffusionsData = completeDiffusionArray(diffusionsData);

                diffusionsData = setCoFilter(diffusionsData, coList);
                diffusionsData = setSpFilter(diffusionsData, spList);
                diffusionsData = completeOfferArrayForDateComparison(diffusionsData);

                diffusionsData.sort((diffA, diffB) => {
                    const dateA = new Date(diffA.compareDate);
                    const dateB = new Date(diffB.compareDate);
                    return dateB - dateA;
                });

                setDiffusions(diffusionsData);
            } else {
                setDiffusions([]);
            }

            const contentHeightValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name") === 0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableCoWidthValue = calculateTableWidth("table-co") === 0 ? "auto" : calculateTableWidth("table-co") + "px";
            const tableSpWidthValue = calculateTableWidth("table-sp") === 0 ? "auto" : calculateTableWidth("table-sp") + "px";
            const tableDatesWidthValue = calculateTableWidth("table-dates") === 0 ? "auto" : calculateTableWidth("table-dates") + "px";

            if(contentHeightValue !== contentHeight || tableContentNameWidthValue !== tableContentNameWidth || tableCoWidthValue !== tableCoWidth || tableSpWidthValue !==tableSpWidth || tableDatesWidthValue !== tableDatesWidth){
                setContentHeight(contentHeightValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableCoWidth(tableCoWidthValue);
                setTableSpWidth(tableSpWidthValue);
                setTableDatesWidth(tableDatesWidthValue);
            }
        }

        init();
    }, []);

    const completeDiffusionArray = (diffusionArray) => {
        for (let i = 0; i < diffusionArray.length; i++) {
            var diffusion = diffusionArray[i];
            diffusion = {
                ...diffusion,
                info: [
                    {
                        label: "identifiant",
                        value: diffusion.content_unique_id
                    }
                ]
            }
            diffusionArray[i] = diffusion;
        }
        return diffusionArray;
    }

    const setCoFilter = (diffusions, coList) => {
        var selectedCo = sessionStorage.getItem("diffusionCoFilter");
        if(selectedCo!==null && selectedCo!=="all"){
            diffusions = diffusions.filter((diffusion) => coList.find(co => co.co_id===diffusion.content_owner).co_name===selectedCo);
        }
        return diffusions;
    }

    const setSpFilter = (diffusions, spList) => {
        diffusions = diffusions.filter((diffusion) => spList.find(sp => sp.sp_id===diffusion.service_provider).sp_name==="Sport All");
        return diffusions;
    }

    const completeOfferArrayForDateComparison = (diffusions) => {
        for (let i = 0; i < diffusions.length; i++) {
            var diffusion = diffusions[i];
            var compareDate = diffusion.valid_time_cp_sp;

            diffusion = {
                ...diffusion,
                compareDate: compareDate
            }
            diffusions[i] = diffusion;
        }
        return diffusions
    }

    const validDate = (serviceStartDate, serviceEndDate) => {
        const startDate = new Date(serviceStartDate);
        const endDate = new Date(serviceEndDate);
        const today = new Date();
        today.setHours(0,0,0,0);
        startDate.setHours(0,0,0,0);
        endDate.setHours(0,0,0,0);
        var validateStatus = true;
        if(startDate.getTime() > today.getTime() || endDate.getTime() < today.getTime()) {
            validateStatus = false;
        }
        return validateStatus;
    }

    const diffusionHandler = async (event, diffusion) => {
        event.preventDefault();
        event.target.style.display = "unset";
        setShowLoader(true);

        const contentId = diffusion.content_unique_id;
        const cpName = diffusion.content_provider_name;
        const contentName = diffusion.content_name;
        const coName = diffusion.content_owner_name;
        const contentType = diffusion.content_type;
        const contentPrice = diffusion.content_price.toString();
        const cpReward = diffusion.cp_reward.toString();
        const spName = diffusion.service_provider_name;
        const servicePrice = diffusion.service_price.toString();
        const serviceStartDate = diffusion.service_start_date;
        const serviceEndDate = diffusion.service_end_date;
        const deliveryDuration = diffusion.delivery_duration;
        const volumeCommitment = diffusion.volume_commitment.toString();

        
        let negotiationResult = {
            cpCode: getCpCode(cpName),
            contentId: getContentCode(contentId, cpName)
        }
        addValidCPSPNegotiationToBC(negotiationResult).then(() => {
            let suppliedContent = {
                cpCode: getCpCode(cpName),
                contentId: getContentCode(contentId, cpName),
                contentName: contentName,
                owner: coName,
                contentType: contentType,
                contentPrice: contentPrice,
                cp: cpName,
                cpReward: cpReward,
                sp: spName,
                servicePrice: servicePrice,
                serviceStartDate: serviceStartDate,
                serviceEndDate: serviceEndDate,
                deliveryDuration: deliveryDuration,
                volumeCommitment: volumeCommitment
            }
            addSuppliedContent(suppliedContent).then(() => {
                updateUploadStatus(diffusion.valid_cp_sp_negotiation).then(()=>{
                    setShowLoader(false);
                    alert(translator(diffusion.content_name + " " + translator("is_now_available")));
                    window.location.reload();
                }).catch(() => {
                    setShowLoader(false);
                    alert(translator("action_failed"))
                })
            }).catch(err => {
                setShowLoader(false);
                setBcErrorOccured(true);
                setBcErr({name: err.name, msg: err.request.response});
            })
        }).catch(err => {
            setShowLoader(false);
            setBcErrorOccured(true);
            setBcErr({name: err.name, msg: err.request.response});
        })
        
    }

    const diffusionAvailabilityTag = (serviceStartDate, serviceEndDate) => {
        var today = new Date();
        today = today.setHours(0,0,0,0);
        var diffusionStartDate = new Date(serviceStartDate);
        diffusionStartDate.setHours(0,0,0,0);
        var diffusionEndDate = new Date(serviceEndDate);
        diffusionEndDate.setHours(0,0,0,0);
        if(today < diffusionStartDate || today > diffusionEndDate){
            return (
                <div className="info-tag info-tag-text">
                    <p className="table-dates" style={{ margin: 0, width: "max-content" }}>
                        {
                            new Date(serviceStartDate).toLocaleDateString(localStorage.getItem("i18nextLng")) + 
                            " - " +
                            new Date(serviceEndDate).toLocaleDateString(localStorage.getItem("i18nextLng"))
                        }
                    </p>
                </div>
            )
        } else {
            return (
                <div className="valid-tag valid-tag-text">
                    <p className="table-dates" style={{ margin: 0, width: "max-content" }}>
                        {
                            new Date(serviceStartDate).toLocaleDateString(localStorage.getItem("i18nextLng")) + 
                            " - " +
                            new Date(serviceEndDate).toLocaleDateString(localStorage.getItem("i18nextLng"))
                        }
                    </p>
                </div>
            )
        }
    }

    const NotUploadedToBC = (diffusion) => {
        return diffusion.uploaded_to_bc===0;
    }

    if(diffusions !== null){
        if(cpNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else if(bcErrorOccured){
            return (
                <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
            )
        } else if(diffusions.length===0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cos={cos}
                                sps={sps}
                            />
                            <NoContent translator={translator} />
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        } else if(diffusions.length!==0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cos={cos}
                                sps={sps}
                            />
                            <div className="table-container" style={{maxHeight: contentHeight}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}>{translator("content_name")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableCoWidth}}>{translator("content_owner")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableSpWidth}}>{translator("service_provider")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableDatesWidth}}>{translator("broadcasting_dates")}</th>
                                            <th style={{color: deco.secondaryColor}}>{translator("action")}</th>      
                                            <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {diffusions.map((diffusion, id) => 
                                            <tr key = {id} >
                                                <td style={{color: deco.secondaryColor}}><p className="table-content-name" style={{margin: 0, width: "max-content"}}>{diffusion.content_name}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p className="table-co" style={{margin: 0, width: "max-content"}}>{cos.find(co => co.co_id===diffusion.content_owner).co_name}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p className="table-sp" style={{margin: 0, width: "max-content"}}>{sps.find(sp => sp.sp_id===diffusion.service_provider).sp_name}</p></td>
                                                <td style={{ paddingRight: "85px" }}> 
                                                    {diffusionAvailabilityTag(diffusion.service_start_date, diffusion.service_end_date)}
                                                </td>
                                                <td style={{whiteSpace: "nowrap"}}>
                                                <span style={{marginRight: "20px"}}>
                                                    {NotUploadedToBC(diffusion) && validDate(diffusion.service_start_date, diffusion.service_end_date) ? 
                                                        <LoadingButton
                                                            text={translator("broadcast")}
                                                            className="table-action-buttons n7-button normal-button" 
                                                            onSubmit={e=>diffusionHandler(e, diffusion)}
                                                            color={deco.secondaryColor}
                                                            loading={showLoader} disabled={showLoader}
                                                        />
                                                         :
                                                        <button 
                                                            className="table-action-buttons n7-button normal-button disabled-button" 
                                                            disabled
                                                            style={{color: deco.secondaryColor, opacity: 0.5, marginRight: "20px"}}
                                                        >
                                                            {translator("broadcast")}
                                                        </button>
                                                    }
                                                    </span>
                                                    <Link to={"/" + cpHash + "/diffusions/" + diffusion.content_state_sp_id + "/viewership"}>
                                                        <input 
                                                            type="button"
                                                            className="table-action-buttons n7-button normal-button" 
                                                            onMouseEnter={e=>hoverSubmitHandler(e)}
                                                            onMouseLeave={e=>unhoverSubmitHandler(e)}
                                                            value={translator("viewership")} 
                                                            style={{color: deco.secondaryColor}}
                                                        />
                                                    </Link>
                                                    <Link to={"/" + cpHash + "/diffusions/" + diffusion.content_state_sp_id + "/checkInfo"}>
                                                        <input 
                                                            type="button"
                                                            className="table-action-buttons n7-button normal-button" 
                                                            onMouseEnter={e=>hoverSubmitHandler(e)}
                                                            onMouseLeave={e=>unhoverSubmitHandler(e)}
                                                            value={translator("check_proposal")} 
                                                            style={{color: deco.secondaryColor}}
                                                        />
                                                    </Link>
                                                </td>
                                                <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                    <span id={"info-pop-up-container-" + id} style={{visibility: "hidden"}}><InfoPopUp infos={diffusion.info} /> </span>
                                                    <img alt="info-pop-up-icon" className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default Diffusions;