import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import {hoverSubmitHandler, unhoverSubmitHandler} from '../components/ButtonManip';
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import {getDiffusionById, getMail} from './ContentManip';
import {getSuppliedContent, checkProof} from '../blockchainManip/BCManip';
import NoContent from '../components/NoContent';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import Error3 from '../errorManip/Error3';
import Error1 from '../errorManip/Error1';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import BCLoadingPage from '../components/BCLoadingPage';
import { getCpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import cpUserDecoRoutes from '../components/CpUserDecoRoutes';

const CheckViewership = ({translator, filters, cpHash}) => {
    const [deco, setDeco] = useState(null);
    const [contentState, setContentState] = useState(null);
    const [mails, setMails] = useState([]);
    const [viewerships, setViewerships] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [tableViewerWidth, setTableViewerWidth] = useState("");
    const [tableProofWidth, setTableProofWidth] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [cpNotExistError, setCpNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const cpData = await getCpByHash(cpHash).then(res => res.data);
            if(cpData!==undefined && cpData.length!==0){
                setDeco(getDeco(cpUserDecoRoutes, cpData[0].cp_name));
            } else {
                setCpNotExistError(true);
            }

            const url = window.location.href;
            const urlArray = url.split("/");
            const contentStateId = urlArray[urlArray.length - 2];
            const contentStateData = await getDiffusionById(contentStateId).then(res => res.data);

            let viewershipsData = [];

            if (contentStateData.length !== 0) {
                if (contentStateData[0].uploaded_to_bc === 1) {
                    const contentCode = getContentCode(contentStateData[0].content_unique_id, contentStateData[0].content_provider_name);
                    const cpCode = getCpCode(contentStateData[0].content_provider_name);
                    const suppliedContent = await getSuppliedContent(contentCode, cpCode).then(res => res.data).catch((err) => {
                        setBcErrorOccured(true);
                        setBcErr({ name: err.name, msg: err.request.response });
                    });

                    if (suppliedContent !== undefined) {
                        viewershipsData = suppliedContent.viewershipVerification;
                        viewershipsData = viewershipsData.filter(viewership => viewership.viewer !== "");
                        viewershipsData = completeInfoArray(contentStateData[0], viewershipsData);
                    }
                }
            }

            const mailsData = await getMail(contentStateId).then(res => res.data);

            setContentState(contentStateData.length === 0 ? contentStateData : contentStateData[0]);
            setViewerships(viewershipsData);
            setMails(mailsData);

            const contentHeightValue = calculateContainerHeight();
            const tableViewerWidthValue = calculateTableWidth("table-viewer") === 0 ? "auto" : calculateTableWidth("table-viewer") + "px";
            const tableProofWidthValue = calculateTableWidth("table-proof") === 0 ? "auto" : calculateTableWidth("table-proof") + "px";

            if (contentHeightValue!==contentHeight || tableViewerWidthValue!==tableViewerWidth || tableProofWidthValue !== tableProofWidth) {
                setContentHeight(contentHeightValue);
                setTableViewerWidth(tableViewerWidthValue);
                setTableProofWidth(tableProofWidthValue);
            }
        };

        init();
    }, []);

    const completeInfoArray = (contentState, viewerArray) => {
        for (let i = 0; i < viewerArray.length; i++) {
            var viewer = viewerArray[i];
            viewer = {
                ...viewer,
                info: [
                    {
                        label: "identifiant",
                        value: contentState.content_unique_id
                    }
                ]
            }
            viewerArray[i] = viewer;
        }
        return viewerArray;
    }

    const proofVerifiedTag = () => {
        return(
            <div className="valid-tag table-proof">
                <div className="valid-tag-text">
                    {translator("proof_verified")}
                </div>
            </div>
        )
    }

    const fakeProofTag = () => {
        return(
            <div className="attention-tag table-proof">
                <div className="attention-tag-text">
                    {translator("fake_proof")}
                </div>
            </div>
        )
    }

    const proofNotVerifiedTag = () => {
        return(
            <div className="attention-tag table-proof">
                <div className="attention-tag-text">
                    {translator("proof_not_verified")}
                </div>
            </div>
        )
    }

    const verificationTag = (validation) => {
        switch (validation) {
            case "":
                return proofNotVerifiedTag();

            case "invalid":
                return fakeProofTag();

            case "valid":
                return proofVerifiedTag();

            default:
                break;
        }
    }

    const checkProofHandler = (viewer, nonce, nonceEncrypted) => {
        let proof = {
            cpCode: getCpCode(contentState.content_provider_name),
            suppliedContentId: getContentCode(contentState.content_unique_id, contentState.content_provider_name),
            viewerId: viewer,
            nonce: nonce,
            encryptedNonce: nonceEncrypted
        }
        checkProof(proof).then(
            res => {
                if(res.data === "Valid proof"){
                    alert(translator("valid_proof"));
                    window.location.reload();
                } else {
                    alert(translator("fake_proof"));
                    window.location.reload();
                }
            }
        ).catch (
            (err) => {
                setBcErrorOccured(true);
                setBcErr({ name: err.name, msg: err.request.response});
            }
        )
    }

    if(contentState!==null && viewerships!==null){
        if(contentState.length===0 || cpNotExistError){
            return <Error1 translator={translator} />
        } else if(bcErrorOccured){
            return <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
        } else if(viewerships.length!==0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator} 
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + cpHash + "/diffusions"}
                                contentName={contentState.content_name}
                                co={contentState.content_owner_name}
                                cp={contentState.content_provider_name}
                                sp={contentState.service_provider_name} 
                            />
                            <div className="table-container" style={{maxHeight: contentHeight}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{color: deco.secondaryColor, width: tableViewerWidth}}>{translator("viewer")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableProofWidth}}>{translator("proof")}</th>    
                                            <th style={{color: deco.secondaryColor}}>{translator("action")}</th>  
                                            <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewerships.map((viewership, id) => 
                                            <tr key = {id} >
                                                <td style={{color: deco.secondaryColor}}><p className="table-viewer" style={{margin: 0, width: "max-content"}}>{viewership.viewer}</p></td>
                                                <td>{verificationTag(viewership.validation)}</td>
                                                <td>
                                                    {viewership.validation!=="" ? 
                                                        <></> :
                                                        <button className="n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} onClick={()=>{checkProofHandler(viewership.viewer, viewership.nonce, viewership.viewershipProof)}} style={{color: deco.secondaryColor}}>{translator("check_proof")}</button> 
                                                    }
                                                </td>
                                                <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                    <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={viewership.info}/> </span>
                                                    <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        } else if(viewerships.length===0){
            return(
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + cpHash +"/diffusions"}
                                contentName={contentState.content_name}
                                co={contentState.content_owner_name}
                                cp={contentState.content_provider_name}
                                sp={contentState.service_provider_name} 
                            />
                            <NoContent translator={translator} />
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else if(viewerships===null){
        return (
            <BCLoadingPage translator={translator} />
        )
    }
}

export default CheckViewership;